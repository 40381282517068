import {
  Button,
  Container,
  Group,
  InputWrapper,
  SimpleGrid,
  Stack,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  fetchAllLocations,
  setScreenType,
  updateLocations,
} from "../../store/slices/locationsSlice";
import { fetchMainOrganization } from "../../store/slices/organizationSlice";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { showNotification } from "@mantine/notifications";
import locationsGeneralStyles from "./LocationsGeneralStyles";
import { TimeInput } from "@mantine/dates";
import { format } from "date-fns";

const schema = yup.object().shape({
  name: yup.string().required("Obavezno polje"),
  address: yup.string().required("Obavezno polje"),
  latitude: yup.string().required("Obavezno polje"),
  longitude: yup.string().required("Obavezno polje"),
  totalSpaces: yup.string().required("Obavezno polje"),
  reservationPrice: yup.string().required("Obavezno polje"),
  city: yup.string().required("Obavezno polje"),
});

const LocationsGeneralEdit = () => {
  const { classes } = locationsGeneralStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [rowData, pageIndex, pageSize] = useOutletContext();
  const [change, setChange] = useState(false);

  useEffect(() => {
    const dispatchFetchMainOrganization = async () => {
      try {
        const organization = await dispatch(fetchMainOrganization()).unwrap();
        return organization;
      } catch (error) {}
    };
    const { id: organizationId } = dispatchFetchMainOrganization();
    dispatch(setScreenType("general"));
    let defaultValues = {};
    defaultValues.id = rowData?.id;
    defaultValues.address = rowData?.address;
    defaultValues.organizationId = organizationId;
    defaultValues.latitude = rowData.latitude;
    defaultValues.longitude = rowData?.longitude;
    defaultValues.name = rowData?.name;
    defaultValues.totalSpaces = rowData?.totalSpaces;
    defaultValues.reservationPrice = rowData?.reservationPrice;
    defaultValues.note = rowData?.note;
    defaultValues.city = rowData?.city;
    defaultValues.workingTimeFrom = rowData?.workingTimeFrom;
    defaultValues.workingTimeTo = rowData?.workingTimeTo;
    reset({ ...defaultValues });
  }, []);

  const today = new Date().toDateString();

  const [workingTimeFrom, setWorkingTimeFrom] = useState(
    new Date(`${today} ${rowData?.workingTimeFrom}`)
  );
  const [workingTimeTo, setWorkingTimeTo] = useState(
    new Date(`${today} ${rowData?.workingTimeTo}`)
  );

  const dispatchUpdateLocations = async (data) => {
    try {
      await dispatch(updateLocations(data))
        .unwrap()
        .then(() => {
          showNotification({
            message: "Uspješno ste ažurirali lokaciju",
            color: "green",
          });
        })
        .catch(() => {
          showNotification({
            title: "Greška",
            message: "Neočekivana greška",
            color: "red",
          });
        });
      await dispatch(fetchAllLocations({ page: pageIndex, size: pageSize }));
    } catch (error) {
      throw error;
    }
  };

  const onSubmit = (data) => {
    dispatchUpdateLocations({
      ...data,
      workingTimeFrom: format(new Date(workingTimeFrom), "HH:mm"),
      workingTimeTo: format(new Date(workingTimeTo), "HH:mm"),
    });
    navigate("../");
  };

  return (
    <>
      {" "}
      <Container className={classes.container}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SimpleGrid
            cols={2}
            spacing="xl"
            className={classes.simpleGridContainer}
          >
            <InputWrapper
              required
              styles={{
                label: { color: "#00498F" },
              }}
              label={"Naziv"}
              error={errors.name && errors.name.message}
            >
              <TextInput disabled={!change} {...register("name")} autoFocus />
            </InputWrapper>
            <InputWrapper
              required
              styles={{
                label: { color: "#00498F" },
              }}
              label={"Longitude"}
              error={errors.longitude && errors.longitude.message}
            >
              <TextInput disabled={!change} {...register("longitude")} />
            </InputWrapper>
            <InputWrapper
              required
              styles={{
                label: { color: "#00498F" },
              }}
              label={"Adresa"}
              error={errors.address && errors.address.message}
            >
              <TextInput disabled={!change} {...register("address")} />
            </InputWrapper>
            <InputWrapper
              required
              styles={{
                label: { color: "#00498F" },
              }}
              label={"Latitude"}
              error={errors.latitude && errors.latitude.message}
            >
              <TextInput disabled={!change} {...register("latitude")} />
            </InputWrapper>
            <InputWrapper
              required
              styles={{
                label: { color: "#00498F" },
              }}
              label={"Grad"}
              error={errors.city && errors.city.message}
            >
              <TextInput disabled={!change} {...register("city")} />
            </InputWrapper>{" "}
            <InputWrapper
              required
              styles={{
                label: { color: "#00498F" },
              }}
              label={"Cijena rezervacije (EUR)"}
              error={errors.reservationPrice && errors.reservationPrice.message}
            >
              <TextInput disabled={!change} {...register("reservationPrice")} />
            </InputWrapper>
            <InputWrapper
              styles={{
                label: { color: "#00498F" },
              }}
              required
              label="Vrijeme od"
            >
              <TimeInput
                disabled={!change}
                value={workingTimeFrom}
                onChange={setWorkingTimeFrom}
                error={
                  workingTimeFrom >= workingTimeTo &&
                  "Vrijeme od ne smije biti veće ili jednako vremenu do"
                }
              />
            </InputWrapper>
            <InputWrapper
              required
              styles={{
                label: { color: "#00498F" },
              }}
              label="Vrijeme do"
            >
              <TimeInput
                disabled={!change}
                value={workingTimeTo}
                onChange={setWorkingTimeTo}
              />
            </InputWrapper>
            <div className={classes.inputWrapper}>
              <InputWrapper
                required
                styles={{
                  label: { color: "#00498F" },
                }}
                label={"Broj punionica"}
                error={errors.totalSpaces && errors.totalSpaces.message}
              >
                <TextInput disabled={!change} {...register("totalSpaces")} />
              </InputWrapper>
            </div>
            <InputWrapper
              styles={{
                label: { color: "#00498F" },
              }}
              label={"Napomena"}
            >
              <Textarea
                sx={() => ({
                  ".mantine-Textarea-defaultVariant": {
                    [`@media (max-width: 1500px)`]: {
                      height:
                        errors.totalSpaces && errors.totalSpaces?.message
                          ? 131
                          : 104,
                    },
                    height:
                      errors.totalSpaces && errors.totalSpaces?.message
                        ? 150
                        : 125,
                  },
                })}
                disabled={!change}
                {...register("note")}
              />
            </InputWrapper>
          </SimpleGrid>

          <Group position="center" className={classes.groupContainer}>
            {change ? (
              <>
                <Button type="submit" color={"green"}>
                  {"Spremi"}
                </Button>
                <Button onClick={() => navigate("../")} color={"red"}>
                  {"Otkaži"}
                </Button>
              </>
            ) : (
              <Button
                onClick={() => setChange(true)}
                styles={{ root: { backgroundColor: "#00498F" } }}
              >
                {"Promijeni"}
              </Button>
            )}
          </Group>
        </form>{" "}
      </Container>
    </>
  );
};

export default LocationsGeneralEdit;
