import { Center, createStyles } from "@mantine/core";
import { colors } from "../../constants/colors";

const locationsGeneralStyles = createStyles((theme) => ({
  locationsEditHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  locationsEditSubHeader: {
    backgroundColor: "#00498F",
    height: 40,
    width: "18%",
    color: "white",

    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },
  divider: {
    backgroundColor: "#00498F",
    height: 40,
    width: "100%",

    justifyContent: "center",
  },

  container: {
    marginTop: 60,
    [`@media (max-width: 1600px)`]: {
      marginTop: 1,
      maxHeight: 325,
      overflowY: "auto",
    },
  },
  simpleGridContainer: {
    gap: 25,
    [`@media (max-width: 1600px)`]: {
      gap: 5,
    },
  },
  groupContainer: {
    marginTop: 40,
    [`@media (max-width: 1600px)`]: {
      marginTop: 5,
    },
  },
  inputWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 25,
    [`@media (max-width: 1600px)`]: {
      display: "flex",
      flexDirection: "column",
      gap: 5,
    },
  },
}));

export default locationsGeneralStyles;
