import { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Group,
  InputWrapper,
  TextInput,
  Switch,
  Text,
} from "@mantine/core";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllVehicles,
  updateVehicles,
  saveVehicles,
  fetchVehicleById,
} from "../../store/slices/vehiclesSlice";
import vehiclesModalStyles from "./VehiclesModalStyle";
import { showNotification } from "@mantine/notifications";

const schema = yup.object().shape({
  brand: yup.string().required("Obavezno"),
  year: yup.number().typeError("Upišite broj").required(),
  model: yup.string().required("Obavezno"),
  battery: yup.string().required("Obavezno"),
  range: yup.string().required("Obavezno"),
});

const VehiclesModal = (props) => {
  const dispatch = useDispatch();
  const { classes } = vehiclesModalStyles();
  const {
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const dispatchFetchVehicleById = async (id) => {
    try {
      const vehicle = await dispatch(fetchVehicleById(id)).unwrap();
      return vehicle;
    } catch (err) {}
  };

  const dispatchUpdateVehicles = async (data) => {
    if (data.activity === "INACTIVE") {
      const vehicle = await dispatchFetchVehicleById(data.id);
      if (vehicle.userAccountListSize !== 0) {
        showNotification({
          title: "Greška",
          message:
            "Ne možete deaktivirati vozilo koje je dodijeljeno nekom korisniku",
          color: "red",
        });
        return;
      }
    }
    try {
      await dispatch(updateVehicles({ ...data }))
        .unwrap()
        .then(() => {
          showNotification({
            message: "Uspješno ste ažurirali vozilo",
            color: "green",
          });
        })
        .catch(() => {
          showNotification({
            title: "Greška",
            message: "Vozilo koje unosite već postoji",
            color: "red",
          });
        });

      await dispatch(fetchAllVehicles({ page: props.page, size: props.size }));
    } catch (error) {
      throw error;
    }
  };
  const dispatchSaveVehicles = async (data) => {
    try {
      await dispatch(saveVehicles({ ...data }))
        .unwrap()
        .then(() => {
          showNotification({
            message: "Uspješno ste spremili vozilo",
            color: "green",
          });
        })
        .catch(() => {
          showNotification({
            title: "Greška",
            message: "Vozilo koje unosite već postoji",
            color: "red",
          });
        });
      await dispatch(fetchAllVehicles({ page: props.page, size: props.size }));
    } catch (error) {
      throw error;
    }
  };

  const isNewVehicle = () => !(props.data && props.data.id);

  const onSubmit = (data) => {
    data.activity = data.activity === true ? "ACTIVE" : "INACTIVE";

    isNewVehicle() ? dispatchSaveVehicles(data) : dispatchUpdateVehicles(data);

    props.close();
  };

  useEffect(() => {
    let defaultValues = {};
    defaultValues.id = props.data?.id;
    defaultValues.brand = props.data?.brand;
    defaultValues.year = props.data?.year;
    defaultValues.model = props.data?.model;
    defaultValues.battery = props.data?.battery;
    defaultValues.range = props.data?.range;
    defaultValues.activity = props.data?.activity === "ACTIVE" ? true : false;

    reset({ ...defaultValues });
  }, [props.data]);

  return (
    <>
      <Modal
        withCloseButton={false}
        centered
        opened={props.show}
        onClose={() => props.close()}
        title="Vozila"
        sx={() => ({
          /* ".mantine-1uvnwva": {
            padding: 2000,
          },
          ".mantine-5o48jf": {
            padding: "0px",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            padding: 20,
            margin: 0,
            height:"1000px"
          },

           ".mantine-jqt8fx": {
            padding: 15,
            backgroundColor: "#7bb7f0",
          },*/
          ".mantine-vos779": {
            padding: 0,

            backgroundColor: "#7bb7f0",
            borderRadius: 12,
          },
          ".mantine-l553vn": {
            padding: 10,
          },
        })}
        classNames={{
          header: classes.headerContainer,
          title: classes.headerText,
        }}
        styles={{}}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputWrapper
            required
            styles={{
              root: { textAlign: "left", padding: "10px" },
              label: { color: "#00498F" },
            }}
            error={errors.brand && errors.brand.message}
            label={"Vozilo"}
          >
            <TextInput {...register("brand")} />
          </InputWrapper>

          <InputWrapper
            required
            styles={{
              root: { textAlign: "left", padding: "10px" },
              label: { color: "#00498F" },
            }}
            error={errors.year && errors.year.message}
            label={"Godina"}
          >
            <TextInput type="number" {...register("year")} />
          </InputWrapper>
          <InputWrapper
            required
            styles={{
              root: { textAlign: "left", padding: "10px" },
              label: { color: "#00498F" },
            }}
            error={errors.model && errors.model.message}
            label={"Model"}
          >
            <TextInput {...register("model")} />
          </InputWrapper>
          <InputWrapper
            required
            styles={{
              root: { textAlign: "left", padding: "10px" },
              label: { color: "#00498F" },
            }}
            error={errors.battery && errors.battery.message}
            label={"Baterija"}
          >
            <TextInput {...register("battery")} />
          </InputWrapper>
          <InputWrapper
            required
            styles={{
              root: { textAlign: "left", padding: "10px" },
              label: { color: "#00498F" },
            }}
            error={errors.range && errors.range.message}
            label={"Domet"}
          >
            <TextInput {...register("range")} />
          </InputWrapper>
          <InputWrapper
            style={{ display: "flex", flexDirection: "column", marginTop: 5 }}
          >
            <Text
              style={{
                color: "#00498F",
                fontWeight: 500,
                fontSize: 14,
                marginLeft: 10,
              }}
            >
              {"Status"}
            </Text>
            <Controller
              control={control}
              name="activity"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { isTouched, isDirty, error },
                formState,
              }) => (
                <Switch
                  sx={(theme) => ({
                    ".mantine-1gg7aeh:checked": {
                      background: "#00498F",
                    },
                    input: { marginLeft: 10 },
                    label: { color: "#00498F" },
                  })}
                  onBlur={onBlur} // notify when input is touched
                  onChange={onChange} // send value to hook form
                  checked={value}
                  ref={ref}
                  label={value ? "Aktivan" : "Neaktivan"}
                />
              )}
            />
          </InputWrapper>
          <Group
            position="center"
            sx={(theme) => ({})}
            className={classes.groupContainer}
          >
            <Button type="submit" color="green" size="lg">
              {"Spremi"}
            </Button>
            <Button color="red" size="lg" onClick={() => props.close()}>
              {"Otkaži"}
            </Button>
          </Group>
        </form>
      </Modal>
    </>
  );
};

export default VehiclesModal;
