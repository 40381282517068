import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AuthenticationApi } from "../../api";

export const fetchAuth = createAsyncThunk(
  "auth/getUser",
  async (data, thunkAPI) => {
    //console.log(data);
    try {
      return await AuthenticationApi.authenticate(data);
    } catch (error) {
      return await thunkAPI.rejectWithValue(error.response.status);
      // return thunkAPI.rejectWithValue(error);
    }
  }
);

const initialState = {
  status: null,
  statusCode: 0,
  email: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearAuth: () => {
      return { ...initialState };
    },
    setEmail: (state, action) => {
      return {
        ...state,
        email: action.payload.email,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAuth.pending, (state) => {
        return { ...state, status: "pending" };
      })
      .addCase(fetchAuth.fulfilled, (state, action) => {
        // console.log(action.payload);

        return {
          ...state,
          email: action.payload.credentials.email,
          status: "fulfilled",
          statusCode: action.payload.statusCode,
        };
      })
      .addCase(fetchAuth.rejected, (state, action) => {
        return { ...state, status: "error", statusCode: action.payload };
      });
  },
});

export const { setEmail, clearAuth } = authSlice.actions;
export default authSlice.reducer;
