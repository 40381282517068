import axios from "./axios";

export const getAllRules = async (args) => {
  const response = await axios.get(
    `/api/v1/rules/locations/${args.locationId}?page=${args.page}&size=${args.size}&sort=createdAt`
  );
  return response.data;
};

export const updateRules = async (args) => {
  await axios.put(`/api/v1/rules/${args.id}`, args);
};

export const saveRules = async (args) => {
  await axios.post(`/api/v1/rules`, args);
};

export const deleteRules = async (args) => {
  await axios.delete(`/api/v1/rules/${args.id}`, args);
};
