import {
  AppShell, Button, Header, Image, Navbar, Title
} from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import images from "../../constants/images";
import { clearAuth } from "../../store/slices/authSlice";
import { setScreenType } from "../../store/slices/locationsSlice";
import { clearToken } from "../../store/slices/otpSlice";
import useStyles from "./adminStyles";

const AdminLayout = () => {
  const navigate = useNavigate();
  let params = useParams();
  const location = useLocation();
  const error = useSelector((state) => state.otpAuth.error);
  const dispatch = useDispatch();
  const { classes } = useStyles();

  return (
    <AppShell
      padding="md"
      sx={() => ({
        ".mantine-khtkeg:hover": {
          backgroundColor: "#232324",
        },
        ".mantine-Navbar-root": classes.navbarContainer,
      })}
      styles={{
        root: { padding: 0 },
        body: { backgroundColor: "#80B4E2" },
      }}
      navbar={
        <Navbar width={{ base: 300 }} p="xs">
          <Navbar.Section
            style={{
              cursor: "pointer",
              backgroundColor: location.pathname === "/" && "black",
            }}
            onClick={() => {
              navigate("./");
            }}
          >
            <Title order={3} style={{ padding: 10 }}>
              {" "}
              Dashboard
            </Title>
          </Navbar.Section>

          <Navbar.Section
            style={{
              cursor: "pointer",
              backgroundColor: location.pathname === "/vehicles" && "black",
            }}
            onClick={() => {
              navigate("vehicles");
            }}
          >
            <Title order={3} style={{ padding: 10 }}>
              {" "}
              Vozila
            </Title>
          </Navbar.Section>

          <Navbar.Section
            style={{
              cursor: "pointer",
              backgroundColor:
                location.pathname === "/organizations" && "black",
            }}
            onClick={() => navigate("organizations")}
          >
            <Title order={3} style={{ padding: 10 }}>
              Organizacija
            </Title>
          </Navbar.Section>
          <Navbar.Section
            style={{
              cursor: "pointer",
              backgroundColor: location.pathname === "/users" && "black",
            }}
            onClick={() => navigate("users")}
          >
            <Title order={3} style={{ padding: 10 }}>
              Korisnici
            </Title>
          </Navbar.Section>

          <Navbar.Section
            style={{
              cursor: "pointer",
              backgroundColor:
                (location.pathname === `/locations` ||
                  location.pathname === `/locations/${params?.locationsId}`) &&
                "black",
            }}
            onClick={() => {
              navigate("locations");
              dispatch(setScreenType("general"));
            }}
          >
            <Title order={3} style={{ padding: 10 }}>
              Lokacije
            </Title>
          </Navbar.Section>
        </Navbar>
      }
      header={
        <Header height={60} p="xs" className={classes.headerContainer}>
          <div className={classes.headerLeftContainer}>
            <Image
              className={classes.headerPicture}
              src={images.mainLogoSmall}
            />
            <span className={classes.headerText}>
              NOVAMINA CENTAR INOVATIVNIH TEHNOLOGIJA
            </span>
          </div>

          <Button
            className={classes.odjavaButton}
            onClick={() => {
              dispatch(clearAuth());
              dispatch(clearToken());
            }}
          >
            Odjava
          </Button>
        </Header>
      }
    >
      <Outlet />
    </AppShell>
  );
};

export default AdminLayout;
