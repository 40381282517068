import { createStyles } from "@mantine/core";
import { colors } from "../../constants/colors";

const organizationStyles = createStyles((theme) => ({
  organizationHeader: {
    backgroundColor: colors.backgroundDark,
    height: 40,
    width: "18%",
    color: colors.textWhite,
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    border: "1px solid",
    borderColor: colors.backgroundDark,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },
  divider: {
    backgroundColor: colors.backgroundDark,
    height: 20,
    width: "100%",
    borderTopRightRadius: "15px",
  },
  tabRoot: {
    paddingTop: "0px",
  },
}));

export default organizationStyles;
