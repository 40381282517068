import { Button, Group } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllLocations,
  setRowData,
  setScreenType,
} from "../../store/slices/locationsSlice";
import TableGeneric from "../Table/Table";
import LocationsModal from "./LocationsModal";
import locationsStyles from "./LocationsStyles";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import LocationsChargersModal from "./LocationsChargersModal";
import { format, parseISO, formatISO } from "date-fns";
import LocationsRulesModal from "./LocationsRulesModal";
import dayjs from "dayjs";

const Locations = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let params = useParams();
  const { classes } = locationsStyles();

  const [showLocationModalType, setShowLocationModalType] = useState(false);

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const getAllLocations = useSelector(
    (state) => state.locations.allLocations?._embedded?.locationResources
  );
  const getTotalPages = useSelector((state) => state.locations.totalPages);

  const pageIndexRules = useSelector((state) => state.rules.pageIndex);
  const pageSizeRules = useSelector((state) => state.rules.pageSize);

  const pageIndexChargers = useSelector((state) => state.chargers.pageIndex);
  const pageSizeChargers = useSelector((state) => state.chargers.pageSize);

  //getting data from row
  const getRowData = useSelector((state) => state.locations.rowData);

  const getScreenTypeAction = useSelector(
    (state) => state.locations.screenType
  );

  const dispatchAllLocations = async () => {
    try {
      await dispatch(fetchAllLocations({ page: pageIndex, size: pageSize }));
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    dispatchAllLocations();
    //dispatch(setScreenType("general"))
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Naziv",
        accessor: "name", // accessor is the "key" in the data
      },

      {
        Header: "Adresa",
        accessor: "address",
      },
      {
        Header: "Grad",
        accessor: "city",
      },

      {
        Header: "Broj mjesta",
        accessor: "totalSpaces",
      },
      {
        Header: "Kreiran",
        accessor: "createdAt",
        Cell: (props) => {
          return props.value
            ? dayjs(props.value).format("DD.MM.YYYY. HH:mm")
            : null;
        },
      },
    ],
    []
  );

  const openLocationModalType = (data) => {
    //setCardStatusModalData(data);
    setShowLocationModalType(true);
  };

  const closeLocationModalType = () => {
    //setCardStatusModalData(null);
    setShowLocationModalType(false);
  };

  const renderScreenTypeActionModal = (type) => {
    switch (type) {
      case "general":
        return (
          <LocationsModal
            show={showLocationModalType}
            close={closeLocationModalType}
            page={pageIndex}
            size={pageSize}
          />
        );

      case "chargers":
        return (
          <LocationsChargersModal
            show={showLocationModalType}
            close={closeLocationModalType}
            page={pageIndexChargers}
            size={pageSizeChargers}
            locationId={getRowData.id}
          />
        );

      case "rules":
        return (
          <LocationsRulesModal
            show={showLocationModalType}
            close={closeLocationModalType}
            page={pageIndexRules}
            size={pageSizeRules}
            locationId={getRowData.id}
          />
        );

      case "transactions":
        return null;
      default:
        return (
          <LocationsModal
            show={showLocationModalType}
            close={closeLocationModalType}
            page={pageIndex}
            size={pageSize}
          />
        );
    }
  };
  const renderScreenTypeActionLabel = (type) => {
    switch (type) {
      case "general":
        return "Dodaj lokaciju";

      case "chargers":
        return "Dodaj punionicu";

      case "rules":
        return "Dodaj pravilo";

      default:
        return "Dodaj lokaciju";
    }
  };
  return (
    <>
      <div className={classes.locationsHeader}>
        <div className={classes.locationsSubHeader}>
          <p>Lokacije</p>
        </div>

        <div style={{ color: "white" }}>
          <span style={{ padding: 5 }}>
            {renderScreenTypeActionLabel(getScreenTypeAction)}
          </span>
          <Button
            styles={{ root: { backgroundColor: "#00498F" } }}
            onClick={() => openLocationModalType()}
          >
            +
          </Button>
          {/*
          <Button onClick={() => navigate("/locations")}></Button>
           */}
        </div>
      </div>
      <div className={classes.divider}></div>
      {params.locationsId && (
        <div className={classes.dividerOptional}>
          {" "}
          {
            <span style={{ marginLeft: 20, color: "white" }}>
              {getRowData?.name}
            </span>
          }
        </div>
      )}

      {params.locationsId ? (
        <Outlet context={[getRowData, pageIndex, pageSize]} />
      ) : (
        <TableGeneric
          columns={columns}
          data={getAllLocations || []}
          totalPages={getTotalPages}
          manualPagination={true}
          manualPageIndex={pageIndex}
          manualPageSize={pageSize}
          manualOnPageIndexChange={(pageIndex) => {
            setPageIndex(pageIndex);
            dispatch(fetchAllLocations({ page: pageIndex, size: pageSize }));
          }}
          manualOnPageSizeChange={(pageSize) => {
            dispatch(fetchAllLocations({ size: pageSize, page: 0 }));
            setPageSize(pageSize);
            //setPageIndex(0)
          }}
          onRowClick={(row) => {
            dispatch(setRowData(row));
            navigate(`${row?.id}`);
          }}
        />
      )}
      {renderScreenTypeActionModal(getScreenTypeAction)}
    </>
  );
};

export default Locations;
