import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ReservationsApi } from "../../api";
import { fetchOperatorById } from "../../api/operator";

export const fetchReservationsByLocation = createAsyncThunk(
  "locations/getReservationsByLocation",
  async (args) => {
    try {
      const reservations = await ReservationsApi.getReservationsByLocation(
        args
      );
      if (reservations._embedded) {
        for (let reservation of reservations._embedded.reservationResources) {
          const user = await fetchOperatorById(reservation.userAccountId);
          reservation.userEmail = user.email;
        }
      }

      return reservations;
    } catch (error) {
      throw error;
    }
  }
);

export const updateExpiredReservations = createAsyncThunk(
  "reservations/updateExpiredReservations",
  async () => {
    try {
      await ReservationsApi.patchExpiredReservations();
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  status: null,
  allReservations: [],
  totalPages: 0,
  totalElements: 0,
  pageSize: 10,
  pageIndex: 0,
};

const reservationsSlice = createSlice({
  name: "reservations",
  initialState,
  reducers: {
    setPageSizeGlobalReservations: (state, action) => {
      return { ...state, pageSize: action.payload };
    },
    setPageIndexGlobalReservations: (state, action) => {
      return { ...state, pageIndex: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReservationsByLocation.fulfilled, (state, action) => {
      return {
        ...state,
        status: "fulfilled",
        allReservations: action.payload,
        totalPages: action.payload.page.totalPages,
        totalElements: action.payload.page.totalElements,
      };
    });
  },
});

export const { setPageIndexGlobalReservations, setPageSizeGlobalReservations } =
  reservationsSlice.actions;

export default reservationsSlice.reducer;
