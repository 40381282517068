import { createStyles } from "@mantine/core";

const useLocationsRulesModalStyles = createStyles((theme) => ({
  headerContainer: {
    backgroundColor: "#00498F",
    color: "white",
    height: 60,
    margin: 0,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    justifyContent: "center",
  },
  headerText: {
    color: "white",
    fontSize: 40,
  },

  groupContainer: {
    padding: 20,
    backgroundColor: "#7bb7f0",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    marginTop: 40,
  },

  mantine_vos779: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: "#7bb7f0",
    borderRadius: 12,
  },
  mantine_l553vn: { padding: 20 },
}));

export default useLocationsRulesModalStyles;
