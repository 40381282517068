import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import store from "../store";
import { clearAuthenticationState, refreshJwt } from "../store/slices/otpSlice";

export const createNewAxiosInstance = () => {
  return axios.create({
    timeout: 60000,
  });
};

const instance = createNewAxiosInstance();

// Authorization interceptor
instance.interceptors.request.use(async (request) => {
  
  const { accessToken } = store.getState().otpAuth;
  //console.log("Token: ", accessToken);
  if (accessToken) {
    request.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  return request;
});

// Jwt refresh interceptor
const authRefreshCallback = async (failedRequest) => {
  try {
    const tokens = await store.dispatch(
      refreshJwt({ axios: createNewAxiosInstance() })
    );

    console.log("Tokens ", tokens);
    failedRequest.response.config.headers[
      "Authorization"
    ] = `Bearer ${tokens.accessToken}`;
    return Promise.resolve();
  } catch (error) {
    store.dispatch(clearAuthenticationState());
    return Promise.reject(failedRequest);
  }
};

createAuthRefreshInterceptor(instance, authRefreshCallback, {
  pauseInstanceWhileRefreshing: true,
});

export default instance;
