import { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Group,
  InputWrapper,
  TextInput,
  Textarea,
  SimpleGrid,
} from "@mantine/core";
import useLocationsModalStyles from "./LocationsModalStyles";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { TimeInput } from "@mantine/dates";
import {
  fetchAllLocations,
  saveLocations,
} from "../../store/slices/locationsSlice";
import { fetchMainOrganization } from "../../store/slices/organizationSlice";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { showNotification } from "@mantine/notifications";
import { format } from "date-fns";

const LocationsModal = (props) => {
  const [workingTimeFrom, setWorkingTimeFrom] = useState(new Date());
  const [workingTimeTo, setWorkingTimeTo] = useState(new Date());
  const [showTimeError, setShowTimeError] = useState(false);
  const { classes } = useLocationsModalStyles();
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    name: yup.string().required("Obavezno polje"),
    address: yup.string().required("Obavezno polje"),
    latitude: yup.string().required("Obavezno polje"),
    longitude: yup.string().required("Obavezno polje"),
    totalSpaces: yup.string().required("Obavezno polje"),
    reservationPrice: yup.number().typeError("Unesite broj"),
    city: yup.string().required("Obavezno polje"),
  });

  const {
    reset,
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const dispatchFetchMainOrganization = async () => {
    try {
      const organization = await dispatch(fetchMainOrganization()).unwrap();
      return organization;
    } catch (error) {}
  };

  const dispatchSaveLocations = async (data) => {
    const { id: organizationId } = await dispatchFetchMainOrganization();
    try {
      await dispatch(saveLocations({ ...data, organizationId: organizationId }))
        .unwrap()
        .then(() => {
          showNotification({
            message: "Uspješno ste spremili lokaciju",
            color: "green",
          });
        })
        .catch(() => {
          showNotification({
            title: "Greška",
            message: "Neočekivana greška",
            color: "red",
          });
        });
      await dispatch(
        fetchAllLocations({ page: props.page, size: props.size })
      ).unwrap();
    } catch (error) {
      throw error;
    }
  };

  const onSubmit = (data) => {
    setShowTimeError(true);
    dispatchSaveLocations({
      ...data,
      workingTimeFrom: format(new Date(workingTimeFrom), "HH:mm"),
      workingTimeTo: format(new Date(workingTimeTo), "HH:mm"),
    });
    reset();
    setWorkingTimeFrom();
    setWorkingTimeTo();
    setShowTimeError(false);
    props.close();
  };

  useEffect(() => {
    reset();
    setWorkingTimeFrom();
    setWorkingTimeTo();
    setShowTimeError(false);
  }, [props]);

  return (
    <>
      <div>
        <Modal
          withCloseButton={false}
          centered
          opened={props.show}
          onClose={() => props.close()}
          title="Lokacije"
          sx={() => ({
            ".mantine-vos779": classes.mantine_vos779,
          })}
          classNames={{
            header: classes.headerContainer,
            title: classes.headerText,
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <SimpleGrid cols={2} spacing="sm" style={{ margin: 20 }}>
              <InputWrapper
                required
                styles={{
                  label: { color: "#00498F" },
                }}
                label={"Naziv"}
                error={errors.name && errors.name.message}
              >
                <TextInput {...register("name")} autoFocus />
              </InputWrapper>

              <InputWrapper
                required
                styles={{
                  label: { color: "#00498F" },
                }}
                label={"Longitude"}
                error={errors.longitude && errors.longitude.message}
              >
                <TextInput {...register("longitude")} />
              </InputWrapper>

              <InputWrapper
                required
                styles={{
                  label: { color: "#00498F" },
                }}
                label={"Adresa"}
                error={errors.address && errors.address.message}
              >
                <TextInput {...register("address")} />
              </InputWrapper>
              <InputWrapper
                required
                styles={{
                  label: { color: "#00498F" },
                }}
                error={errors.latitude && errors.latitude.message}
                label={"Latitude"}
              >
                <TextInput {...register("latitude")} />
              </InputWrapper>

              <InputWrapper
                required
                styles={{
                  label: { color: "#00498F" },
                }}
                label={"Grad"}
                error={errors.city && errors.city.message}
              >
                <TextInput {...register("city")} />
              </InputWrapper>

              <InputWrapper
                required
                styles={{
                  label: { color: "#00498F" },
                }}
                label={"Cijena rezervacije (EUR)"}
                error={
                  errors.reservationPrice && errors.reservationPrice.message
                }
              >
                <TextInput {...register("reservationPrice")} />
              </InputWrapper>

              <InputWrapper
                styles={{
                  label: { color: "#00498F" },
                }}
                required
                label="Vrijeme od"
                error={
                  showTimeError &&
                  workingTimeFrom == undefined &&
                  "Obvezan podatak"
                }
              >
                <TimeInput
                  value={workingTimeFrom}
                  onChange={setWorkingTimeFrom}
                  error={
                    workingTimeFrom >= workingTimeTo &&
                    "Vrijeme od ne smije biti veće ili jednako vremenu do"
                  }
                />
              </InputWrapper>
              <InputWrapper
                required
                styles={{
                  label: { color: "#00498F" },
                }}
                label="Vrijeme do"
                error={
                  showTimeError &&
                  workingTimeTo == undefined &&
                  "Obvezan podatak"
                }
              >
                <TimeInput value={workingTimeTo} onChange={setWorkingTimeTo} />
              </InputWrapper>

              <div tyle={{ display: "flex", flexDirection: "column", gap: 24 }}>
                <InputWrapper
                  required
                  styles={{
                    label: { color: "#00498F" },
                  }}
                  label={"Broj punionica"}
                  error={errors.totalSpaces && errors.totalSpaces.message}
                >
                  <TextInput {...register("totalSpaces")} />
                </InputWrapper>
              </div>

              <InputWrapper
                sx={(theme) => ({
                  ".mantine-Textarea-defaultVariant": {
                    height:
                      errors.totalSpaces && errors.totalSpaces?.message
                        ? 127
                        : 101,
                  },
                })}
                styles={{
                  root: { width: 185 },
                  label: { color: "#00498F" },
                  wrapper: { color: "red" },
                }}
                label={"Napomena"}
              >
                <Textarea {...register("note")} />
              </InputWrapper>
            </SimpleGrid>
            <Group position="center">
              <Button
                type="submit"
                color="green"
                size="lg"
                onClick={() => setShowTimeError(true)}
              >
                {"Spremi"}
              </Button>
              <Button
                color="red"
                size="lg"
                onClick={() => {
                  props.close();
                  reset();
                  setWorkingTimeFrom();
                  setWorkingTimeTo();
                  setShowTimeError(false);
                }}
              >
                {"Otkaži"}
              </Button>
            </Group>
          </form>
        </Modal>
      </div>
    </>
  );
};

export default LocationsModal;
