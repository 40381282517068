import { Button, InputWrapper, TextInput } from "@mantine/core";
import { Notification } from "@mantine/core";
import { X } from "tabler-icons-react";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import useStylesInput from "../LoginLayout/loginStyles";
import { Navigate, useNavigate } from "react-router-dom";
import {
  isValidEmail,
  isEmailExisting,
  renderError,
} from "../../helpers/email";
import { useDispatch, useSelector } from "react-redux";
import { authenticate } from "../../api/authentication";
import { fetchAuth, setEmail } from "../../store/slices/authSlice";
import { showNotification } from "@mantine/notifications";
import OtpInput from "react-otp-input";
import OTPInput from "../OtpInput/OtpInput";

import { clearToken } from "../../store/slices/otpSlice";
import instance from "../../api/axios";
const LoginInput = () => {
  const dispatch = useDispatch();

  const status = useSelector((state) => state.auth.status);
  const email = useSelector((state) => state.auth.email);

  const [isValid, setIsValid] = useState(false);

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm();

  const dispatchAuthenticate = async (data) => {
    try {
      //dispatch(setEmail(data.email))
      await dispatch(fetchAuth(data))
        .unwrap()
        .then((payload) => {
        
          setIsValid(true);
        })
        .catch((error) => {
         
          setIsValid(false);
          showNotification({ message: "Nepostojeći korisnik", color: "red" });
        });
    } catch (error) {}
  };

  const onSubmit = async (data) => {
    await dispatchAuthenticate({ email: data.email.toLowerCase() });
  };

  useEffect(() => {
    dispatch(clearToken());
  }, []);

  return (
    <div>
      {isValid === false ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputWrapper
            required
            styles={{
              root: { textAlign: "left" },
              label: { color: "#00498F" },
            }}
            label={"EMAIL ADRESA"}
            error={renderError(errors)}
          >
            <TextInput
              autoFocus
              {...register("email", {
                required: true,

                validate: {
                  isValidEmail,
                },
              })}
            />
          </InputWrapper>

          <Button
            type="submit"
            disabled={status === "pending"}
            loading={status === "pending"}
            styles={(theme) => ({
              root: {
                backgroundColor: "#00498F",
                width: "100%",
                marginTop: "5px",

                active: {
                  backgroundColor: theme.colors[theme.primaryColor][6],
                  color: theme.white,
                },
              },
            })}
          >
            {"Login"}
          </Button>
        </form>
      ) : (
        <OTPInput email={email} dispatchAuthenticate={dispatchAuthenticate} />
      )}
    </div>
  );
};

export default LoginInput;
