import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  headerContainer: {
    backgroundColor: "#00498F",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "none",
    boxShadow: "0 0 15px black",
    clipPath: "inset(0px 0px -15px 0px)",
  },
  headerPicture: {
    width: 55,
  },
  headerText: {
    width: "100%",
    paddingLeft: "1%",
    color: "white",
  },
  headerLeftContainer: {
    backgroundColor: "#00498F",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  odjavaButton: {
    backgroundColor: "#00498F",
    border: "1px solid white",
    marginRight: "0.5%",
  },
  navbarContainer: {
    backgroundColor: "#00498F",
    color: "white",
    borderRight: "none",
    boxShadow: "0 0 15px black",
    clipPath: "inset(0px -15px 0px 0px)",
    textAlign:"center",
    padding:0
  },
}));

export default useStyles;
