import { createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  container: {
    display: "flex",
    height: "100%",
    width: "100%",
  },
  loginDivLeft: { 
    height: "100vh",
    width: "30vw",
    backgroundColor: "#00498F",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
 },
  loginDivContent: {
    height: "100vh",
    width: "70vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
  },
  loginDivInput: {
    display:"flex",
    flex: 1,
    flexDirection:"column",
    justifyContent: "center",
    width: "50%",
    alignItems: "center",
    textAlign: "center",
    alignContent: "center",
  },
  loginPicture :{
    flex: 10,
    width:"100%",
  },
  loginPictureLeft :{
    width:"80%",
  },
  loginForm:{
    width:"100%",
    flex:4
  },
  button: {
    backgroundColor: "green",
  },
}));

export default useStyles;
