import axios from "./axios";

export const getAllLocations = async (args) => {
  const response = await axios.get(
    `/api/v1/locations?page=${args.page}&size=${args.size}&sort=createdAt`
  );
  return response.data;
};

export const updateLocations = async (args) => {
  await axios.put(`/api/v1/locations/${args.id}`, args);
};

export const saveLocations = async (args) => {
  await axios.post(`/api/v1/locations`, args);
};

export const deleteLocations = async (args) => {
  await axios.delete(`/api/v1/locations/${args.id}`, args);
};

export const getLocationById = async (id) => {
  const response = await axios.get(`/api/v1/locations/${id}`);
  return response.data;
};
