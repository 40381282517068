import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getTransactionsFromOrganization,
  getTransactionsFromUser,
  getTransactionsFromLocation,
  getExportDataForOrganization,
  getExportDataForLocation,
  getExportDataForUser,
} from "../../api/transaction";

export const fetchTransactionsByUser = createAsyncThunk(
  "transaction/fetchByUser",
  async (args) => {
    return await getTransactionsFromUser(args);
  }
);
export const fetchTransactionByOrganization = createAsyncThunk(
  "transaction/fetchByOrganization",
  async (args) => {
    return await getTransactionsFromOrganization(args);
  }
);
export const fetchTransactionByLocation = createAsyncThunk(
  "transaction/fetchByLocation",
  async (args) => {
    return await getTransactionsFromLocation(args);
  }
);

export const fetchExportData = createAsyncThunk(
  "transaction/fetchExportData",
  async (args) => {
    return await getExportDataForOrganization(args);
  }
);

export const fetchExportDataByLocation = createAsyncThunk(
  "transaction/fetchExportDataByLocation",
  async (args) => {
    return await getExportDataForLocation(args);
  }
);

export const fetchExportDataByUser = createAsyncThunk(
  "transaction/fetchExportDataByUser",
  async (args) => {
    return await getExportDataForUser(args);
  }
);

const initialState = {
  tableData: [],
  totalPages: 0,
  totalElements: 0,
};

const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTransactionsByUser.fulfilled, (state, action) => {
      var data = [];
      try {
        data = action.payload._embedded.transactionResources;
      } catch (e) {}
      return {
        ...state,
        tableData: data,
        totalElements: action.payload.page.totalElements,
        totalPages: action.payload.page.totalPages,
      };
    });
    builder.addCase(
      fetchTransactionByOrganization.fulfilled,
      (state, action) => {
        var data = [];
        try {
          data = action.payload._embedded.transactionResources;
        } catch (e) {}
        return {
          ...state,
          tableData: data,
          totalElements: action.payload.page.totalElements,
          totalPages: action.payload.page.totalPages,
        };
      }
    );
    builder.addCase(fetchTransactionByLocation.fulfilled, (state, action) => {
      var data = [];
      try {
        data = action.payload._embedded.transactionResources;
      } catch (e) {}
      return {
        ...state,
        tableData: data,
        totalElements: action.payload.page.totalElements,
        totalPages: action.payload.page.totalPages,
      };
    });
  },
});
export default transactionSlice.reducer;
