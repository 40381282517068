import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import otpSlice from "./slices/otpSlice";
import vehiclesSlice from "./slices/vehiclesSlice";
import locationsSlice from "./slices/locationsSlice";
import organizationSlice from "./slices/organizationSlice";
import operatorSlice from "./slices/operatorSlice";
import transactionSlice from "./slices/transactionSlice";
import chargersSlice from "./slices/chargersSlice";
import rulesSlice from "./slices/rulesSlice";
import dashboardSlice from "./slices/dashboardSlice";
import reservationsSlice from "./slices/reservationsSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["otpAuth"],
  blacklist: ["auth"],
};

const rootReducer = combineReducers({
  auth: authSlice,
  otpAuth: otpSlice,
  vehicles: vehiclesSlice,
  locations: locationsSlice,
  organization: organizationSlice,
  operator: operatorSlice,
  transaction: transactionSlice,
  chargers: chargersSlice,
  rules: rulesSlice,
  dashboard: dashboardSlice,
  reservations: reservationsSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export const persistor = persistStore(store);
export default store;
