import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { VehiclesApi } from "../../api";

export const fetchAllVehicles = createAsyncThunk(
  "vehicles/getAllVehicles",
  async (args, thunkAPI) => {
    //console.log("Thunk test ", args);

    return await VehiclesApi.getAllVehicles(args);
  }
);
export const updateVehicles = createAsyncThunk(
  "vehicles/updateVehicles",
  async (args, thunkAPI) => {
    return await VehiclesApi.updateVehicles(args);
  }
);
export const saveVehicles = createAsyncThunk(
  "vehicles/saveVehicles",
  async (args, thunkAPI) => {
    return await VehiclesApi.saveVehicles(args);
  }
);
export const deleteVehicles = createAsyncThunk(
  "vehicles/deleteVehicles",
  async (args, thunkAPI) => {
    return await VehiclesApi.deleteVehicles(args);
  }
);

export const fetchVehicleById = createAsyncThunk(
  "vehicles/fetchVehicleById",
  async (args) => {
    const response = await VehiclesApi.getVehicleById(args);
    return response;
  }
);

const initialState = {
  status: null,
  allVehicles: [],
  totalPages: 0,
  totalElements: 0,
  pageSize: 10,
  pageIndex: 0,
};

const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState,
  reducers: {
    setPageSizeVehicles: (state, action) => {
      return { ...state, pageSize: action.payload };
    },
    setPageIndexVehicles: (state, action) => {
      return { ...state, pageIndex: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllVehicles.pending, (state, action) => {
        return {
          ...state,
          status: "pending",
        };
      })
      .addCase(fetchAllVehicles.fulfilled, (state, action) => {
        return {
          ...state,
          status: "fulfilled",
          allVehicles: action.payload,
          //page:action.payload.page,
          totalPages: action.payload.page.totalPages,
          totalElements: action.payload.page.totalElements,
          // pageSize:action.payload.page.size,
          // pageIndex:action.payload.page.number
        };
      })
      .addCase(fetchAllVehicles.rejected, (state, action) => {
        // console.log("VEhicles ", action.payload);

        return {
          ...state,
          status: "error",
        };
      })
      .addCase(updateVehicles.pending, (state, action) => {
        return {
          ...state,

          status: "pending",
        };
      })
      .addCase(updateVehicles.fulfilled, (state, action) => {
        return {
          ...state,

          status: "fulfilled",
        };
      })
      .addCase(updateVehicles.rejected, (state, action) => {
        return {
          ...state,
          status: "error",
        };
      })
      .addCase(saveVehicles.pending, (state, action) => {
        return {
          ...state,
          status: "pending",
        };
      })
      .addCase(saveVehicles.fulfilled, (state, action) => {
        return {
          ...state,
          status: "fulfilled",
        };
      })
      .addCase(saveVehicles.rejected, (state, action) => {
        return {
          ...state,
          status: "error",
        };
      })
      .addCase(deleteVehicles.pending, (state, action) => {
        return {
          ...state,
          status: "pending",
        };
      })
      .addCase(deleteVehicles.fulfilled, (state, action) => {
        return {
          ...state,
          status: "fulfilled",
        };
      })
      .addCase(deleteVehicles.rejected, (state, action) => {
        return {
          ...state,
          status: "error",
        };
      });
  },
});

export const { setPageIndexVehicles, setPageSizeVehicles } =
  vehiclesSlice.actions;
export default vehiclesSlice.reducer;
