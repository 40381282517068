import axios from "./axios";

export const getAllVehicles = async (args) => {
  const response = await axios.get(
    `/api/v1/vehicles?page=${args.page}&size=${args.size}&sort=brand`
  );
  return response.data;
};

export const updateVehicles = async (args) => {
  await axios.put(`/api/v1/vehicles/${args.id}`, args);
};

export const saveVehicles = async (args) => {
  await axios.post(`/api/v1/vehicles`, args);
};

export const deleteVehicles = async (args) => {
  await axios.delete(`/api/v1/vehicles/${args.id}`, args);
};

export const getVehicleById = async (id) => {
  const response = await axios.get(`/api/v1/vehicles/${id}`);
  return response.data;
};
