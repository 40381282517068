import { createStyles } from "@mantine/core";
import { colors } from "../../constants/colors";

const usersStyles = createStyles((theme) => ({
  userHeader: {
    backgroundColor: colors.backgroundDark,
    height: 40,
    width: "18%",
    color: colors.textWhite,
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    border: "1px solid",
    borderColor: colors.backgroundDark,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },
  divider: {
    backgroundColor: colors.backgroundDark,
    height: 20,
    width: "100%",
    borderTopRightRadius: "15px",
  },
  tableButtons: {
    backgroundColor: colors.buttonGreen,
  },
  dialog: {
    width: "500px",
    height: "200px",
    backgroundColor: colors.backgroundLight,
    borderColor: colors.backgroundLight,
    color: colors.textWhite,
  },
  dialogContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    alignContent: "center",
    height: "100%",
  },
  headingContainer: {
    flex: 8,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "70%",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "80%",
    flex: 10,
  },
  confirmButton: {
    width: "40%",
    backgroundColor: colors.buttonGreen,
  },
  cancelButton: {
    width: "40%",
    backgroundColor: colors.buttonRed,
  },
  dropdownRoot: {
    width: "13%",
    borderRadius: "10px",
    borderColor: colors.backgroundDark,
  },
  dropdownWrapper: { borderColor: colors.backgroundDark },
  dropDownFilled: {
    backgroundColor: colors.backgroundDark,
    borderColor: colors.backgroundDark,
    orderColor: colors.backgroundDark,
  },
  dropDown: {
    backgroundColor: colors.backgroundDark,
    borderColor: colors.backgroundDark,
  },
  dropDownInput: { color: colors.textWhite },
  dropDownSelected: { borderColor: colors.backgroundDark, color: "red" },
  dropDownHovered: { backgroundColor: colors.backgroundLight },
  dropDownItem: { color: colors.textWhite },
}));

export default usersStyles;
