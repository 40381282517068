import { createStyles } from "@mantine/core";
import { colors } from "../../constants/colors";
const organizationsOperatorsStyles = createStyles((theme) => ({
  floatingDivButton: {
    position: "absolute",
    float: "right",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    textAlign: "center",
    alignItems: "center",
    width: "50%",
    right: "1vw",
    top: "70px",
  },
  fontWhite: {
    color: colors.textWhite,
  },
  floatingButton: {
    marginLeft: "2%",
    background: colors.backgroundDark,
    MozBoxShadow: "10px 10px 5px #222",
    WebkitBoxShadow: "10px 10px 5px #222",
    boxShadow: "5px 5px 10px #222",
  },
  promijeniButton: {
    backgroundColor: colors.buttonGreen,
  },
  dropdownRoot: {
    width: "13%",
    borderRadius: "10px",
    borderColor: colors.backgroundDark,
  },
  dropdownWrapper: {},
  dropDownFilled: {
    backgroundColor: colors.backgroundDark,
    borderColor: colors.backgroundDark,
    orderColor: colors.backgroundDark,
  },
  dropDown: {
    backgroundColor: colors.backgroundDark,
    borderColor: colors.backgroundDark,
  },
  dropDownInput: { color: colors.textWhite },
  dropDownSelected: { borderColor: colors.backgroundDark },
  dropDownHovered: { backgroundColor: colors.backgroundLight },
  dropDownItem: { color: colors.textWhite },
  tableContainer: {
    height: "68vh",
    backgroundColor: colors.backgroundGraph,
    borderColor: colors.backgroundDark,
    borderTopRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    overflowY: "scroll",
    "::-webkit-scrollbar": {
      backgroundColor: colors.backgroundDark,
    },
    "::-webkit-scrollbar": {
      width: "15px",
      marginLeft: "30px",
    },
    "::-webkit-scrollbar-thumb": {
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px",
      backgroundColor: colors.backgroundDark,
      border: "1px solid black",
    },
    "::-webkit-scrollbar-track-piece ": {
      backgroundColor: colors.tableHeaderDark,
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
    "::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
  },
}));
export default organizationsOperatorsStyles;
