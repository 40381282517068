import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { OtpApi } from "../../api";

export const sendOtp = createAsyncThunk(
  "auth/sendOtp",
  async (data, thunkAPI) => {
    //console.log(data);
    try {
      return await OtpApi.authenticateOtp(data);
    } catch (error) {
      return await thunkAPI.rejectWithValue(error.response.status);
    }
  }
);

const initialState = {
  status: "",
  otp: "",
  email: "",
  accessToken: "",
  refreshToken: "",
  statusCode: 0,
};

const otpSlice = createSlice({
  name: "otpAuth",
  initialState,
  reducers: {
    updateToken: (state, action) => {
      console.log(action.payload);
      return { ...state, ...action.payload };
    },
    clearToken: () => {
      return { accessToken: null, refreshToken: null };
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(sendOtp.pending, (state) => {
        return { ...state, status: "pending",statusCode:0};
      })
      .addCase(sendOtp.fulfilled, (state, action) => {
        return {
          ...state,

          status: "fulfilled",
          email: action.payload.credentials.email,
          otp: action.payload.credentials.authorizationCode,
          accessToken: action.payload.accessToken,
          refreshToken: action.payload.refreshToken,
          
        };
      })
      .addCase(sendOtp.rejected, (state, action) => {
        return { ...state, status: "error",statusCode: action.payload };
      });
  },
});

export const clearAuthenticationState = createAsyncThunk(
  "otpAuth/clearAuthenticationState",
  async (args, { dispatch }) => {
    dispatch(clearToken());
  }
);

export const refreshJwt = createAsyncThunk(
  "otpAuth/refreshToken",
  async (args, { getState, dispatch }) => {
    const { refreshToken } = getState().otpAuth;
    //console.log("REFRESH TOKEN ", refreshToken);
    if (!refreshToken) {
      throw new Error("Refresh token not available");
    }



    const { axios } = args;
    //console.log(axios);
    const tokens = await OtpApi.reissueTokens(axios, refreshToken);
    //console.log("Refresh JWT ", tokens);
    dispatch(updateToken({ ...tokens }));
    //console.log("TOKENI novi ", tokens);
    return tokens;
  }
);

export const { updateToken, clearToken } = otpSlice.actions;
export default otpSlice.reducer;
