import axios from "./axios";

export const updateOrganization = async (id, args) => {
  var response = await axios.put(`/api/v1/organizations/${id}`, args);
  return response.data;
};

export const getOrganizationById = async (id) => {
  var response = await axios.get(`/api/v1/organizations/${id}`);
  return response.data;
};

export const updateOrganizationCertificate = async (
  id,
  password,
  alias,
  formData
) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    params: {
      password,
      alias,
    },
  };
  var response = await axios.post(
    `/api/v1/organizations/${id}/certificate`,
    formData,
    config
  );
  return response.data;
};

export const getMainOrganization = async () => {
  const response = await axios.get("/api/v1/organizations/main");
  return response.data;
};
