import { createStyles } from "@mantine/core";
import { colors } from "../../constants/colors";

const organizationsGeneralStyles = createStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  root: {
    width: "31vw",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    color: colors.textWhite,
    paddingTop: "2vh",
    paddingBottom: "2vh",
    paddingLeft: "1vw",
  },
  label: {
    color: colors.textWhite,
  },
  wrapper: {
    width: "60%",
  },
  filledVariant: {
    border: "1px solid",
    borderColor: colors.backgroundDark,
    backgroundColor: colors.backgroundDark,
  },
  input: {
    color: colors.textWhite,
    borderRadius: "0px",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    width: "330px",
  },
  middleButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "2vh",
  },
  buttonFilled: {
    backgroundColor: colors.backgroundDark,
  },
  buttonRoot: {
    width: "12%",
    height: "40px",
    borderRadius: "5px",
  },
  certifikatHeader: {
    paddingTop: "2vh",
    borderBottom: "1px solid",
    borderColor: colors.textWhite,
    width: "80%",
  },
  uploadContainer: {
    width: "50%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    textAlign: "center",
    alignItems: "center",
  },
  buttonContainer: {
    display: "flex",
    width: "60%",
    flexDirection: "row",
    justifyContent: "space-between",
    color: colors.textWhite,
  },
  uploadButton: {
    backgroundColor: colors.backgroundDark,
    padding: "10px",
    border: "1px solid",
    borderColor: colors.backgroundDark,
    borderRadius: "5px",
  },
  certifikatContainer: {
    display: "flex",
    flexDirection: "column",
  },
  certRoot: {
    width: "55%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: "2vh",
    paddingBottom: "2vh",
    paddingLeft: "1vw",
  },
  certLabelContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  cartPassLabel: {
    width: "8ada0%",
    color: colors.textWhite,
  },
  certImage: {
    width: "50px",
    paddingLeft: "60px",
  },
  certPassInput: {
    width: "330px",
  },
}));
export default organizationsGeneralStyles;
