import { Button, Select, Overlay } from "@mantine/core";
import React, { useEffect, useMemo, useState } from "react";
import organizationsOperatorsStyles from "./OrganizationsOperatorsStyles";
import OperatorsModal from "./OperatorsModal";
import TableGeneric from "../Table/Table";
import {
  fetchOperators,
  fetchOperatorByIdThunk,
} from "../../store/slices/operatorSlice";
import { useDispatch, useSelector } from "react-redux";
import * as dayjs from "dayjs";
import { clearOperatorState } from "../../store/slices/operatorSlice";
import { showNotification } from "@mantine/notifications";

const OrganizationsOperators = () => {
  const { classes } = organizationsOperatorsStyles();
  const [operatorsModalVisible, setOperatorsModalVisible] = useState(false);
  const [updateId, setUpdateId] = useState("0");
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [status, setStatus] = useState(null);
  const tableData = useSelector((state) => state.operator.tableData);
  const getTotalPages = useSelector((state) => state.operator.totalPages);
  const dispatch = useDispatch();

  const changeButton = (id) => {
    return (
      <Button
        color={"green"}
        size="sm"
        onClick={() => {
          setUpdateId(id);
          dispatch(fetchOperatorByIdThunk(id));
          setOperatorsModalVisible(true);
        }}
      >
        {"Promijeni"}
      </Button>
    );
  };

  const dataRows = useMemo(() => {
    return tableData.map(({ person, email, id, createdAt }) => {
      const firstName = person?.firstName ? person.firstName : "";
      const lastName = person?.lastName ? person.lastName : "";
      return {
        name: firstName + " " + lastName,
        email: email,
        created: dayjs(createdAt).format("DD.MM.YYYY. HH:mm"),
        button: changeButton(id),
      };
    });
  }, [tableData]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchAllOperators = async (page, size) => {
    try {
      const activity = status;
      const userAccountType = "OPERATOR";
      dispatch(
        fetchOperators({
          activity,
          userAccountType,
          page,
          size,
        })
      ).unwrap();
    } catch (e) {
      showNotification({
        message: "Dogodila se neočekivana greška",
        color: "red",
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    fetchAllOperators(pageIndex, pageSize);
  }, [operatorsModalVisible, status]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = useMemo(
    () => [
      {
        Header: "EMAIL",
        accessor: "email",
      },
      {
        Header: "IME I PREZIME",
        accessor: "name",
      },
      {
        Header: "KREIRAN",
        accessor: "created",
      },
      {
        Header: "",
        accessor: "button",
      },
    ],
    []
  );

  const closeModal = () => {
    setOperatorsModalVisible(false);
    fetchAllOperators(0, pageSize);
  };

  return (
    <>
      {operatorsModalVisible && (
        <Overlay opacity={0.6} color="#000" zIndex={5} />
      )}
      <div className={classes.floatingDivButton}>
        <span className={classes.fontWhite}>Dodaj operatera</span>
        <Button
          classNames={{
            root: classes.floatingButton,
          }}
          onClick={() => {
            dispatch(clearOperatorState());
            setUpdateId("0");
            setOperatorsModalVisible(true);
          }}
        >
          +
        </Button>
      </div>
      <TableGeneric
        columns={columns}
        data={dataRows}
        totalPages={getTotalPages}
        manualPagination={true}
        manualPageIndex={pageIndex}
        manualPageSize={pageSize}
        manualOnPageIndexChange={(pageIndex) => {
          setPageIndex(pageIndex);
          fetchAllOperators(pageIndex, pageSize);
        }}
        manualOnPageSizeChange={(pageSize) => {
          setPageSize(pageSize);
          fetchAllOperators(0, pageSize);
        }}
      />
      <Select
        classNames={{
          root: classes.dropdownRoot,
          dropdown: classes.dropDown,
          defaultVariant: classes.dropDownFilled,
          input: classes.dropDownInput,
          wrapper: classes.dropdownWrapper,
          selected: classes.dropdownSelected,
          hovered: classes.dropDownHovered,
          item: classes.dropDownItem,
        }}
        data={[
          { value: null, label: "Svi" },
          { value: "ACTIVE", label: "Aktivni" },
          { value: "INACTIVE", label: "Neaktivni" },
        ]}
        value={status}
        onChange={(e) => {
          setPageIndex(0);
          setStatus(e);
        }}
      />

      <OperatorsModal
        userType={"Operater"}
        updateId={updateId}
        opened={operatorsModalVisible}
        onClose={closeModal}
        closeOnClickOutside={true}
        fetchAllOperators={fetchAllOperators}
        pageSize={pageSize}
      />
    </>
  );
};

export default OrganizationsOperators;
