import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getReservationsForDashboard,
  getReservationsGraphData,
  getStatisticsForDashboard,
  getEnergyGraphData,
} from "../../api/dashboard";
const initialState = {
  reservationsTableData: [],
  reservationsTotalPages: 0,
  reservationsTotalElements: 0,
  statisticsTableData: [],
  statisticsTotalPages: 0,
  statisticsTotalElements: 0,
  reservationsGraphData: [],
  energyGraphData: [],
};

export const fetchDashboardReservations = createAsyncThunk(
  "dashboard/reservations",
  async (args) => {
    return await getReservationsForDashboard(args);
  }
);

export const fetchDashboardStatistics = createAsyncThunk(
  "dashboard/statistics",
  async (args) => {
    return await getStatisticsForDashboard(args);
  }
);

export const fetchReservationsGraphData = createAsyncThunk(
  "dashboard/reservationsGraph",
  async () => {
    return await getReservationsGraphData();
  }
);

export const fetchEnergyByDayGraphData = createAsyncThunk(
  "dashboard/energyGraph",
  async () => {
    return await getEnergyGraphData();
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReservationsGraphData.fulfilled, (state, action) => {
      var data = [];
      try {
        data = action.payload;
      } catch (e) {}
      return {
        ...state,
        reservationsGraphData: data,
      };
    });
    builder.addCase(fetchDashboardReservations.fulfilled, (state, action) => {
      var data = [];
      try {
        data = action.payload._embedded.reservationsByLocationResources;
      } catch (e) {}
      return {
        ...state,
        reservationsTableData: data,
        reservationsTotalElements: action.payload.page.totalElements,
        reservationsTotalPages: action.payload.page.totalPages,
      };
    });
    builder.addCase(fetchDashboardStatistics.fulfilled, (state, action) => {
      var data = [];
      try {
        data = action.payload._embedded.transactionAmountByLocationResources;
      } catch (e) {}
      return {
        ...state,
        statisticsTableData: data,
        statisticsTotalElements: action.payload.page.totalElements,
        statisticsTotalPages: action.payload.page.totalPages,
      };
    });
    builder.addCase(fetchEnergyByDayGraphData.fulfilled, (state, action) => {
      var data = [];
      try {
        data = action.payload;
      } catch (e) {}
      return {
        ...state,
        energyGraphData: data,
      };
    });
  },
});

export default dashboardSlice.reducer;
