import { Button, Group, Modal, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useDispatch, useSelector } from "react-redux";
import { deleteRules, fetchAllRules, setPageIndexGlobalRules } from "../../store/slices/rulesSlice";
import locationsRulesModalDeleteStyles from "./LocationsRulesModalDeleteStyles";

const LocationsRulesModalDelete = (props) => {
  const { show, data, close, size, locationId } = props;
  const dispatch = useDispatch();
  const { classes } = locationsRulesModalDeleteStyles();
 

  const dispatchDeleteLocationsRules = async (data) => {
    try {
      await dispatch(deleteRules(data))
        .unwrap()
        .then(() => {
          showNotification({
            message: "Uspješno ste obrisali pravilo",
            color: "green",
          });
        })
        .catch(() => {
          showNotification({
            title: "Greška",
            message: "Neočekivana greška",
            color: "red",
          });
        });

      await dispatch(
        fetchAllRules({ page: 0, size: size, locationId: locationId })
      );
     dispatch(setPageIndexGlobalRules(0));
    } catch (error) {
      throw error;
    }
  };

  return (
    <Modal
      centered
      withCloseButton={false}
      opened={show}
      title={"Upozorenje"}
      onClose={() => close()}
      sx={() => ({
        ".mantine-121w2fi": {
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          backgroundColor: "#fa5252",
          justifyContent: "center",
          margin: 0,
          padding: 0,
          border: "1px solid #fa5252",
        },
        ".mantine-1k3lcel": {
          backgroundColor: "#7bb7f0",
          borderRadius: 11,
          padding: 0,
        },
      })}
      classNames={{
        modal: classes.modalContainer,
        title: classes.headerText,
      }}
    >
      <Text align="center" className={classes.bodyText}>
        {"Želite li obrisati pravilo?"}
      </Text>
      <div className={classes.buttonContainer}>
        <Group position="center">
          <Button
            color="green"
            size="sm"
            onClick={() => {
              dispatchDeleteLocationsRules(data);
              close();
            }}
          >
            {"Obriši"}
          </Button>
          <Button color="red" size="sm" onClick={() => close()}>
            {"Odustani"}
          </Button>
        </Group>
      </div>
    </Modal>
  );
};

export default LocationsRulesModalDelete;
