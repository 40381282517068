import { createStyles } from "@mantine/core";

const locationsStyles = createStyles((theme) => ({
  locationsHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  locationsSubHeader: {
    backgroundColor: "#00498F",
    height: 40,
    width: "18%",
    color: "white",
 
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },
  divider: {
    backgroundColor: "#00498F",
    height: 20,
    width: "100%",
    borderTopRightRadius: "15px",
  },
  dividerOptional: {
    backgroundColor: "#00498F",
    height: 30,
    width: "100%",
 
  },
}));

export default locationsStyles;
