export const mainLogoBig = require("../assets/images/Artboard 2@2x.png");
export const mainLogoSmall = require("../assets/images/Artboard 2.png");
export const orangeCar = require("../assets/images/car.png");
export const uploadImg = require("../assets/images/upload.png");

export default {
  mainLogoBig,
  mainLogoSmall,
  orangeCar,
  uploadImg,
};
