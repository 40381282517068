import React, { useEffect, useMemo, useState } from "react";
import TableGeneric from "../Table/Table";
import { useDispatch, useSelector } from "react-redux";
import reservationsStyles from "./ReservationStyles";
import { fetchDashboardReservations } from "../../store/slices/dashboardSlice";
import { Center, Group, Tooltip } from "@mantine/core";
import { ChevronDown, ChevronUp, Select, Selector } from "tabler-icons-react";

export const ReservationsItem = () => {
  const { classes } = reservationsStyles();

  const [state, setState] = React.useState({
    page: 0,
    size: 10,
    sortByAttribute: null,
    sortType: null,
  });

  React.useEffect(() => {
    dispatch(fetchDashboardReservations(state));
  }, [state]);

  const tableData = useSelector(
    (state) => state.dashboard.reservationsTableData
  );
  const getTotalPages = useSelector(
    (state) => state.dashboard.reservationsTotalPages
  );
  const dispatch = useDispatch();

  const columns = useMemo(
    () => [
      {
        Header: () => {
          return (
            <Tooltip
              withArrow
              transition="fade"
              transitionDuration={200}
              label="Sortiraj po nazivu"
              styles={{
                arrow: { backgroundColor: "#3B5975" },
                body: { backgroundColor: "#3B5975" },
              }}
            >
              {" "}
              <Group
                position="apart"
                styles={{
                  root: {
                    /*  "&:hover": {
                      backgroundColor: "grey",
                    }, */
                  },
                }}
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setState((prev) => ({
                      ...prev,
                      sortByAttribute: "l.name",
                      sortType:
                        prev.sortByAttribute !== "l.name"
                          ? "DESC"
                          : prev.sortType === "ASC"
                          ? "DESC"
                          : "ASC",
                    }));
                  }}
                >
                  {"NAZIV"}
                </div>
                <Center>
                  {state.sortByAttribute === "l.name" ? (
                    state.sortType === "ASC" ? (
                      <ChevronDown />
                    ) : (
                      <ChevronUp />
                    )
                  ) : (
                    <Selector />
                  )}
                </Center>
              </Group>
            </Tooltip>
          );
        },
        accessor: "name",
      },
      {
        Header: () => {
          return (
            <Tooltip
              withArrow
              transition="fade"
              transitionDuration={200}
              label={"Sortiraj po broju rezervacija"}
              styles={{
                arrow: { backgroundColor: "#3B5975" },
                body: { backgroundColor: "#3B5975" },
              }}
            >
              {" "}
              <Group
                position="apart"
                styles={{
                  root: {
                    /* "&:hover": {
                      backgroundColor: "grey",
                    }, */
                  },
                }}
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setState((prev) => ({
                      ...prev,
                      sortByAttribute: "reservationCount",
                      sortType:
                        prev.sortByAttribute !== "reservationCount"
                          ? "DESC"
                          : prev.sortType === "ASC"
                          ? "DESC"
                          : "ASC",
                    }));
                  }}
                >
                  BROJ REZERVACIJA
                </div>
                <Center>
                  {state.sortByAttribute === "reservationCount" ? (
                    state.sortType === "ASC" ? (
                      <ChevronDown />
                    ) : (
                      <ChevronUp />
                    )
                  ) : (
                    <Selector />
                  )}
                </Center>
              </Group>
            </Tooltip>
          );
        },
        accessor: "resCount",
      },
    ],
    [state]
  );

  const dataRows = useMemo(() => {
    return tableData.map(({ locationName, reservationCount }) => {
      return {
        name: locationName,
        resCount: reservationCount,
      };
    });
  }, [tableData]); 

  return (
    <>
      <div className={classes.userHeader}>
        <span>Rezervacije</span>
      </div>
      <div className={classes.divider}></div>
      <div className={classes.contentContainer}>
        <TableGeneric
          multipleTablesOnScreen={true}
          columns={columns}
          data={dataRows}
          totalPages={getTotalPages}
          manualPagination={true}
          manualPageIndex={state.page}
          manualPageSize={state.size}
          manualOnPageIndexChange={(page) => {
            setState({ ...state, page });
          }}
          manualOnPageSizeChange={(size) => {
            setState({ ...state, size, page: 0 });
          }}
        />
      </div>
    </>
  );
};
export default ReservationsItem;
