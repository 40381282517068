import axios from "./axios";

export const authenticateOtp = async (credentials) => {
  console.log("Credentials: ", credentials);

  const response = await axios.post("/api/v1/auth/token", credentials, {
    skipAuthRefresh: true,
  });
  //console.log("response: ", response);
  return {
    statusCode: response.status,
    accessToken: response.data.accessToken,
    refreshToken: response.data.refreshToken,
    credentials,
  };
};

export const reissueTokens = async (axios, refreshToken) => {

  const response = await axios.post(
    `/api/v1/auth/token/refresh?refreshToken=${refreshToken}`
  );
  

  return response.data;
};
/*
export const refreshTokensForAuthOtp = async (axios, refreshToken) => {
  const response = await axios.post(
    refreshToken
     `/api/v1/auth/token/refresh?refreshToken=${refreshToken}`,
  );
  console.log("Refresh response ", response)
  return response.data;
};
*/
