import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Button,
  Group,
  InputWrapper,
  Modal,
  Switch,
  TextInput,
  Text,
  Container,
} from "@mantine/core";
import { Controller, useForm } from "react-hook-form";
import useLocationsChargersModalStyles from "./LocationsChargersModalStyles";
import { useEffect } from "react";
import {
  fetchAllChargers,
  saveChargers,
  updateChargers,
  fetchConnectorUuidAndQr,
} from "../../store/slices/chargersSlice";
import { showNotification } from "@mantine/notifications";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";

const schema = yup.object().shape({
  name: yup.string().required("Obavezno polje"),
});

const LocationsChargersModal = (props) => {
  const dispatch = useDispatch();
  const { classes } = useLocationsChargersModalStyles();

  const pageIndexChargers = useSelector((state) => state.rules.pageIndex);
  const pageSizeChargers = useSelector((state) => state.rules.pageSize);

  const dispatchFetchConnectorUuidAndQr = async (locationId) => {
    try {
      const response = dispatch(fetchConnectorUuidAndQr(locationId)).unwrap();
      return response;
    } catch (error) {}
  };

  const dispatchUpdateLocationsChargers = async (data) => {
    try {
      await dispatch(updateChargers({ ...data, locationId: props.locationId }))
        .unwrap()
        .then(() => {
          showNotification({
            message: "Uspješno ste ažurirali punionicu",
            color: "green",
          });
        })
        .catch(() => {
          showNotification({
            title: "Greška",
            message: "Ne možete deaktivirati punionicu",
            color: "red",
          });
        });

      await dispatch(
        fetchAllChargers({
          locationId: props.locationId,
          page: props.page,
          size: props.size,
        })
      );
    } catch (error) {
      throw error;
    }
  };

  const dispatchSaveLocationsChargers = async (data) => {
    const { connectorID } = await dispatchFetchConnectorUuidAndQr(
      props.locationId
    );
    try {
      await dispatch(
        saveChargers({ ...data, locationId: props.locationId, id: connectorID })
      )
        .unwrap()
        .then(() => {
          showNotification({
            message: "Uspješno ste spremili punionicu",
            color: "green",
          });
        })
        .catch(() => {
          showNotification({
            title: "Greška",
            message: "Neočekivana greška",
            color: "red",
          });
        });
      await dispatch(
        fetchAllChargers({
          locationId: props.locationId,
          page: pageIndexChargers,
          size: pageSizeChargers,
        })
      );
    } catch (error) {
      throw error;
    }
  };

  const {
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const isNewLocationsChargers = () => !(props.data && props.data.id);

  const onSubmit = (data) => {
    data.activity = data.activity === true ? "ACTIVE" : "INACTIVE";

    isNewLocationsChargers()
      ? dispatchSaveLocationsChargers(data)
      : dispatchUpdateLocationsChargers(data);
    reset();
    props.close();
    reset();
  };

  useEffect(() => {
    let defaultValues = {};
    defaultValues.id = props.data?.id;
    defaultValues.name = props.data?.name;
    defaultValues.activity = props.data?.activity === "ACTIVE" ? true : false;

    reset({ ...defaultValues });
  }, [props]);

  return (
    <>
      <div>
        <Modal
          withCloseButton={false}
          centered
          opened={props.show}
          onClose={() => props.close()}
          title={"Punionica"}
          sx={() => ({
            ".mantine-vos779": classes.mantine_vos779,

            ".mantine-l553vn": {
              padding: 20,
            },
          })}
          classNames={{
            header: classes.headerContainer,
            title: classes.headerText,
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            {!!props.data && (
              <InputWrapper
                required
                styles={{
                  root: { textAlign: "left" },
                  label: { color: "#00498F" },
                }}
                label={"UUID"}
              >
                <TextInput readOnly {...register("id")} disabled />
              </InputWrapper>
            )}
            <InputWrapper
              required
              styles={{
                root: { textAlign: "left" },
                label: { color: "#00498F" },
              }}
              label={"Naziv"}
              error={errors.name && errors.name.message}
            >
              <TextInput autoFocus {...register("name")} />
            </InputWrapper>
            <InputWrapper
              style={{ display: "flex", flexDirection: "column", marginTop: 5 }}
            >
              <Text
                style={{
                  color: "#00498F",
                  fontWeight: 500,
                  fontSize: 14,
                  marginBottom: -10,
                }}
              >
                {"Status"}
              </Text>
              <Controller
                control={control}
                name="activity"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { isTouched, isDirty, error },
                  formState,
                }) => (
                  <Switch
                    sx={(theme) => ({
                      ".mantine-1gg7aeh:checked": {
                        background: "#00498F",
                      },
                      input: { marginTop: 10 },
                      label: { color: "#00498F", marginTop: 10 },
                    })}
                    onBlur={onBlur} // notify when input is touched
                    onChange={onChange} // send value to hook form
                    checked={value}
                    ref={ref}
                    label={value ? "Aktivna" : "Neaktivna"}
                  />
                )}
              />
            </InputWrapper>

            <Group
              position="center"
              sx={(theme) => ({})}
              className={classes.groupContainer}
            >
              <Button type="submit" color="green" size="lg">
                {"Spremi"}
              </Button>
              <Button
                color="red"
                size="lg"
                onClick={() => {
                  props.close();
                  reset();
                }}
              >
                {"Otkaži"}
              </Button>
            </Group>
          </form>
        </Modal>
      </div>
    </>
  );
};

export default LocationsChargersModal;
