import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableGeneric from "../Table/Table";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  fetchExportData,
  fetchTransactionByLocation,
  fetchTransactionByOrganization,
  fetchTransactionsByUser,
  fetchExportDataByLocation,
  fetchExportDataByUser,
} from "../../store/slices/transactionSlice";
import { Controller, useForm } from "react-hook-form";
import * as dayjs from "dayjs";
import { Button } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import TrasactionStyles from "./TransactionsStyles";
import { colors } from "../../constants/colors";
import { showNotification } from "@mantine/notifications";
import { Calendar } from "tabler-icons-react";

const Transactions = (props) => {
  const schema = yup.object().shape({
    dateFrom: yup.date().nullable(),
    dateTo: yup.date().when("dateFrom", {
      is: (dateFrom) => {
        var isInvalid =
          dateFrom === "Invalid Date" ||
          dateFrom === "" ||
          dateFrom === undefined
            ? true
            : false;
        return isInvalid;
      },
      then: yup.date().nullable(),
      otherwise: yup
        .date()
        .typeError("Polje za datum ne smije ostati prazno")
        .min(yup.ref("dateFrom"), "Datum do ne može biti veći od datuma od")
        .nullable(),
    }),
  });
  const {
    handleSubmit,
    control,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  let params = useParams();
  const { classes } = TrasactionStyles();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const tableData = useSelector((state) => state.transaction.tableData);
  const getTotalPages = useSelector((state) => state.transaction.totalPages);
  const dispatch = useDispatch();
  const watchAllFields = watch();

  const parseName = (transactionType) => {
    if (transactionType === "RESERVATION") return "Rezervacija";
    else if (transactionType === "CHARGE") return "Punjenje";
    else if (transactionType === "PREPAID") return "Nadoplata";
    else return "";
  };
  const dataRows = useMemo(() => {
    return tableData.map(
      ({ customerEmail, transactionType, transactionDate, amount }) => {
        return {
          user: customerEmail,
          name: parseName(transactionType),
          created: dayjs(transactionDate).format("DD.MM.YYYY HH:mm"),
          amount: amount,
        };
      }
    );
  }, [tableData]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = useMemo(
    () => [
      {
        Header: "KORISNIK",
        accessor: "user",
      },
      {
        Header: "NAZIV",
        accessor: "name",
      },
      {
        Header: "KREIRAN",
        accessor: "created",
      },
      {
        Header: "IZNOS",
        accessor: "amount",
        Cell: (props) => {
          return props.value ? `${Number(props.value).toFixed(2)} €` : `0,00 €`;
        },
      },
    ],
    []
  );

  const prepareFileForDownload = (data) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `transakcije.xlsx`);
    document.body.appendChild(link);
    link.click();
  };

  const parseDate = (date) => {
    if (date === undefined || date === null || date === "Invalid Date")
      return "";
    return dayjs(date).format("YYYY-MM-DD");
  };

  const fetchTableData = () => {
    let dateFromFormatted = parseDate(watchAllFields["dateFrom"]);
    let dateToFormatted = parseDate(watchAllFields["dateTo"]);
    if (dateToFormatted < dateFromFormatted && dateToFormatted !== "") {
      showNotification({
        title: "Greška",
        message: "Datum od je veći od datuma do!",
        color: "red",
      });
    }
    let args = {
      page: pageIndex,
      size: pageSize,
      id: "",
      dateFrom: dateFromFormatted === "Invalid Date" ? "" : dateFromFormatted,
      dateTo: dateToFormatted === "Invalid Date" ? "" : dateToFormatted,
    };
    if (props.organizationId !== undefined) {
      dispatch(fetchTransactionByOrganization(args));
    } else if (props.locationId !== undefined) {
      args.id = props.locationId;
      dispatch(fetchTransactionByLocation(args));
    } else if (params.id !== undefined) {
      args.id = params.id;
      dispatch(fetchTransactionsByUser(args));
    }
  };
  useEffect(() => {
    fetchTableData(); // eslint-disable-line react-hooks/exhaustive-deps
  }, [
    pageIndex,
    pageSize,
    watchAllFields["dateFrom"], // eslint-disable-line react-hooks/exhaustive-deps
    watchAllFields["dateTo"], // eslint-disable-line react-hooks/exhaustive-deps
  ]);

  const onSubmit = async (data) => {
    let dateFromFormatted = parseDate(data.dateFrom);
    let dateToFormatted = parseDate(data.dateTo);
    let args = {
      page: pageIndex,
      size: "",
      id: "",
      sort: "asc",
      dateFrom: dateFromFormatted === "Invalid Date" ? "" : dateFromFormatted,
      dateTo: dateToFormatted === "Invalid Date" ? "" : dateToFormatted,
    };
    if (params.id !== undefined) {
      args.id = params.id;
      dispatch(fetchExportDataByUser(args))
        .unwrap()
        .then((data) => {
          prepareFileForDownload(data);
        })
        .catch((e) => {
          showNotification({
            message: "Greška kod preuzimanja dokumenta!",
            color: "red",
            autoClose: 3000,
          });
        });
    } else if (props.organizationId !== undefined) {
      dispatch(fetchExportData(args))
        .unwrap()
        .then((data) => {
          prepareFileForDownload(data);
        })
        .catch((e) => {
          showNotification({
            message: "Greška kod preuzimanja dokumenta!",
            color: "red",
            autoClose: 3000,
          });
        });
    } else if (props.locationId !== undefined) {
      args.id = props.locationId;
      dispatch(fetchExportDataByLocation(args))
        .unwrap()
        .then((data) => {
          prepareFileForDownload(data);
        })
        .catch((e) => {
          showNotification({
            message: "Greška kod preuzimanja dokumenta!",
            color: "red",
            autoClose: 3000,
          });
        });
    }
    clearErrors();
  };
  return (
    <>
      {params.id !== undefined ? (
        <>
          <div className={classes.transactionHeader}>
            <span>Transakcije</span>
          </div>
          <div className={classes.divider}></div>
        </>
      ) : (
        <></>
      )}

      <TableGeneric
        customTableHeight={600}
        columns={columns}
        data={dataRows}
        totalPages={getTotalPages}
        manualPagination={true}
        manualPageIndex={pageIndex}
        manualPageSize={pageSize}
        manualOnPageIndexChange={(pageIndex) => {
          setPageIndex(pageIndex);
        }}
        manualOnPageSizeChange={(pageSize) => {
          setPageSize(pageSize);
        }}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.datePickerFormContainer}>
          <div className={classes.datePicker}>
            <p className={classes.datePickerLabel}>OD</p>
            <Controller
              name="dateFrom"
              control={control}
              render={({ field: { onChange, value, ref } }) => (
                <DatePicker
                  sx={(theme) => ({
                    ".mantine-1tokbep": {
                      color: colors.backgroundDark,
                    },
                  })}
                  classNames={{
                    input: classes.datePickerInput,
                    icon: classes.datePickerInput,
                  }}
                  placeholder="Odaberite datum"
                  required
                  locale="hr"
                  value={value}
                  ref={ref}
                  onChange={onChange}
                  icon={<Calendar size={16} />}
                  error={errors.dateFrom && errors.dateFrom.message}
                />
              )}
            />
          </div>
          <div className={classes.datePicker}>
            <p className={classes.datePickerLabel}>DO</p>
            <Controller
              control={control}
              name="dateTo"
              render={({ field: { onChange, ref, value } }) => (
                <DatePicker
                  sx={(theme) => ({
                    ".mantine-1tokbep": {
                      color: colors.backgroundDark,
                    },
                  })}
                  classNames={{
                    input: classes.datePickerInput,
                    icon: classes.datePickerInput,
                  }}
                  placeholder="Odaberite datum"
                  required
                  locale="hr"
                  ref={ref}
                  value={value}
                  onChange={onChange}
                  icon={<Calendar size={16} />}
                  error={errors.dateTo && errors.dateTo.message}
                />
              )}
            />
          </div>
          <Button
            color={"green"}
            size="sm"
            type="submit"
            style={{ width: "15%", height: "40px" }}
          >
            Export
          </Button>
        </div>
      </form>
    </>
  );
};
export default Transactions;
