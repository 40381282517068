import {
  Modal,
  Button,
  InputWrapper,
  TextInput,
  Switch,
  Group,
} from "@mantine/core";
import operatorsModalStyles from "./OperatorsModalStyles";
import { useDispatch } from "react-redux";
import {
  createOperatorThunk,
  fetchOperatorByIdThunk,
  updateOperatorThunk,
} from "../../store/slices/operatorSlice";
import { fetchMainOrganization } from "../../store/slices/organizationSlice";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { showNotification } from "@mantine/notifications";

const OperatorsModal = (props) => {
  const { classes } = operatorsModalStyles();
  const dispatch = useDispatch();

  const dispatchFetchMainOrganization = async () => {
    try {
      const organization = await dispatch(fetchMainOrganization()).unwrap();
      return organization;
    } catch (error) {}
  };

  const dispatchFetchUserById = async (id) => {
    try {
      const user = await dispatch(fetchOperatorByIdThunk(id)).unwrap();
      return user;
    } catch (err) {}
  };

  const onSubmit = async (data) => {
    const { id: organizationId } = await dispatchFetchMainOrganization();
    var userAccountType = "OPERATOR";
    if (props.updateId === "0") {
      dispatch(
        createOperatorThunk({
          person: {
            lastName: data.lastName,
            firstName: data.firstName,
            countryId: "",
            city: "",
            adress: "",
          },
          email: data.email,
          activity: data.activity === true ? "ACTIVE" : "INACTIVE",
          userAccountType,
          organizationId: organizationId,
          termsAndConditionsConfirmed: true,
          privacyPolicyConfirmed: true,
        })
      )
        .unwrap()
        .then(() => {
          props.fetchAllOperators(0, props.pageSize);
          showNotification({
            message: props.userType + " je uspješno unesen!",
            color: "green",
            autoClose: 3000,
          });
        })
        .catch((e) => {
          showNotification({
            message: "Greška kod unosa " + props.userType + "a!",
            color: "red",
            autoClose: 3000,
          });
        });
    } else {
      const user = await dispatchFetchUserById(props.updateId);
      dispatch(
        updateOperatorThunk({
          person: {
            id: user.id,
            lastName: data.lastName,
            firstName: data.firstName,
            countryId: user.person.countryId,
            city: user.person.city,
            address: user.person.address,
          },
          activity: data.activity === true ? "ACTIVE" : "INACTIVE",
          termsAndConditionsConfirmed: true,
          privacyPolicyConfirmed: true,
        })
      )
        .unwrap()
        .then(() => {
          props.fetchAllOperators(0, props.pageSize);
          showNotification({
            message: props.userType + " je uspješno ažuriran!",
            color: "green",
            autoClose: 3000,
          });
        })
        .catch((e) => {
          showNotification({
            message: "Greška kod ažuriranja " + props.userType + "a!",
            color: "red",
            autoClose: 3000,
          });
        });
    }
    props.onClose();
  };
  useEffect(() => {
    let defaultValues = {};
    if (props.updateId !== "0") {
      dispatch(fetchOperatorByIdThunk(props.updateId))
        .unwrap()
        .then((data) => {
          setValue("firstName", data.person.firstName);
          setValue("lastName", data.person.lastName);
          setValue("email", data.email);
          setValue("activity", data.activity === "ACTIVE" ? true : false);
        });
    } else {
      defaultValues.firstName = "";
      defaultValues.lastName = "";
      defaultValues.email = "";
      defaultValues.activity = false;
    }
    reset({ ...defaultValues });
  }, [props.opened, props.updateId]); // eslint-disable-line react-hooks/exhaustive-deps

  const schema = yup.object().shape({
    email: yup
      .string()
      .required("Morate unijeti email!")
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Unesite email ispravnog formata!"
      ),
  });
  const {
    reset,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const activity = watch("activity");

  return (
    <Modal
      centered
      sx={(theme) => ({
        ".mantine-Paper-root ": {
          padding: "0px",
        },
      })}
      classNames={{
        modal: classes.modalContainer,
        overlay: classes.modalRoot,
      }}
      withCloseButton={false}
      opened={props.opened}
      onClose={() => props.onClose()}
    >
      <div>
        <div className={classes.headerCointainer}>
          {props.updateId !== "0" ? (
            <span className={classes.headerText}>{props.userType}</span>
          ) : (
            <span className={classes.headerText}>Dodaj {props.userType}a</span>
          )}
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.formContainer}>
            <InputWrapper
              classNames={{
                root: classes.root,
                label: classes.label,
              }}
              label={"EMAIL"}
              required
            >
              <TextInput
                readOnly={props.updateId !== "0" ? true : false}
                disabled={props.updateId !== "0" ? true : false}
                classNames={{
                  root: classes.wrapper,
                  defaultVariant: classes.filledVariant,
                  input: classes.input,
                }}
                {...register("email")}
                error={errors.email && errors.email.message}
              />
            </InputWrapper>
            {props.userType != "Korisnik" && (
              <>
                <InputWrapper
                  classNames={{
                    root: classes.root,
                    label: classes.label,
                  }}
                  label={"IME"}
                >
                  <TextInput
                    classNames={{
                      root: classes.wrapper,
                      defaultVariant: classes.filledVariant,
                      input: classes.input,
                    }}
                    {...register("firstName")}
                    error={errors.firstName && errors.firstName.message}
                  />
                </InputWrapper>
                <InputWrapper
                  classNames={{
                    root: classes.root,
                    label: classes.label,
                  }}
                  label={"PREZIME"}
                >
                  <TextInput
                    classNames={{
                      root: classes.wrapper,
                      defaultVariant: classes.filledVariant,
                      input: classes.input,
                    }}
                    {...register("lastName")}
                    error={errors.lastName && errors.lastName.message}
                  />
                </InputWrapper>{" "}
              </>
            )}
            <InputWrapper
              classNames={{
                root: classes.root,
                label: classes.label,
              }}
              label={"STATUS"}
            >
              <Switch
                sx={(theme) => ({
                  ".mantine-1gg7aeh:checked": {
                    background: "white",
                  },
                  ".mantine-1gg7aeh:checked::before": {
                    backgroundColor: "green",
                    borderColor: "green",
                  },
                  ".mantine-1gg7aeh::before": {
                    backgroundColor: "red",
                    borderColor: "red",
                  },
                })}
                label={activity ? "Aktivan" : "Neaktivan"}
                classNames={{
                  input: classes.switchFilled,
                  root: classes.switchRoot,
                  label: classes.switchLabel,
                }}
                {...register("activity")}
              />
            </InputWrapper>
          </div>
          <Group
            position="center"
            sx={(theme) => ({})}
            className={classes.groupContainer}
          >
            <Button type="submit" color="green" size="lg">
              Spremi
            </Button>
            <Button color="red" size="lg" onClick={() => props.onClose()}>
              Otkaži
            </Button>
          </Group>
        </form>
      </div>
    </Modal>
  );
};
export default OperatorsModal;
