import axios from "./axios";

export const getAllChargers = async (args) => {
  const response = await axios.get(
    `/api/v1/charging-stations/locations/${args.locationId}?page=${args.page}&size=${args.size}&sort=createdAt`
  );
  return response.data;
};

export const updateChargers = async (args) => {
  await axios.put(`/api/v1/charging-stations/${args.id}`, args);
};

export const saveChargers = async (args) => {
  await axios.post(`/api/v1/charging-stations`, args);
};
