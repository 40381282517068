import { createStyles } from "@mantine/core";
import { colors } from "../../constants/colors";
const operatorsModalStyles = createStyles((theme) => ({
  modalContainer: {
    backgroundColor: "transparent",
    padding: "0px",
  },
  modalRoot: {
    padding: "0px",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
  },
  headerCointainer: {
    borderColor: colors.backgroundDark,
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    background: colors.backgroundDark,
    height: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  headerText: {
    color: colors.textWhite,
  },
  formContainer: {
    backgroundColor: colors.backgroundLight,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    paddingTop: "40px",
  },
  root: {
    width: "80%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    color: colors.textWhite,
    paddingTop: "2vh",
    paddingBottom: "2vh",
    paddingLeft: "1vw",
  },
  label: {
    color: colors.textWhite,
    width: "20%",
  },
  wrapper: {
    width: "70%",
  },
  filledVariant: {
    border: "1px solid white",
  },
  input: {
    borderRadius: "0px",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    width: "100%",
  },
  buttonContainer: {
    backgroundColor: colors.backgroundLight,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "40px",
    paddingBottom: "20px",
    borderColor: colors.backgroundDark,
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
  },
  buttonFilledGreen: {
    backgroundColor: colors.buttonGreen,
  },
  buttonFilledRed: {
    backgroundColor: colors.buttonRed,
  },
  buttonRoot: {
    marginRight: "12px",
    width: "25%",
    height: "35px",
    borderRadius: "5px",
  },
  switchRoot: {
    marginRight: "45%",
  },
  switchLabel: {
    color: colors.textWhite,
  },
  groupContainer: {
    padding: 20,
    backgroundColor: colors.backgroundLight,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
}));
export default operatorsModalStyles;
