import axios from "./axios";

export const createOperator = async (args) => {
  var response = await axios.post("/api/v1/users", args);
  return response.data;
};

export const updateOperator = async (args) => {
  var response = await axios.put(`/api/v1/users/${args.person.id}`, args);
  return response.data;
};

export const fetchOperatorsPageable = async (args) => {
  var response = await axios.get("/api/v1/users", {
    params: { ...args, sort: "createdAt" },
  });
  return response.data;
};

export const fetchOperatorById = async (id) => {
  var response = await axios.get(`/api/v1/users/${id}`);
  return response.data;
};
