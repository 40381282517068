import { createStyles } from "@mantine/core";
import { colors } from "../../constants/colors";
const reservationsStyles = createStyles((theme) => ({
  userHeader: {
    backgroundColor: colors.backgroundDark,
    height: 40,
    width: "20vh",
    color: colors.textWhite,
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    border: "1px solid",
    borderColor: colors.backgroundDark,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },
  divider: {
    backgroundColor: colors.backgroundDark,
    height: 20,
    width: "100%",
    borderTopRightRadius: "15px",
  },
  contentContainer: {
    height: "78%",
    backgroundColor: colors.backgroundGraph,
    borderColor: colors.backgroundDark,
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    overflowY: "scroll",
    "::-webkit-scrollbar": {
      backgroundColor: colors.backgroundDark,
    },
    "::-webkit-scrollbar": {
      width: "15px",
      marginLeft: "30px",
    },
    "::-webkit-scrollbar-thumb": {
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px",
      backgroundColor: colors.backgroundDark,
      border: "1px solid black",
    },
    "::-webkit-scrollbar-track-piece ": {
      backgroundColor: colors.backgroundDark,
      borderBottomRightRadius: "10px",
    },
    "::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    boxShadow: "0px 15px 10px -15px #111",
  },
}));
export default reservationsStyles;
