import { createStyles } from "@mantine/core";
import { colors } from "../../constants/colors";
const energyItemStyles = createStyles((theme) => ({
  userHeader: {
    backgroundColor: colors.backgroundDark,
    height: 40,
    width: "20vh",
    color: colors.textWhite,
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    border: "1px solid",
    borderColor: colors.backgroundDark,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },
  divider: {
    backgroundColor: colors.backgroundDark,
    height: 20,
    width: "100%",
    borderTopRightRadius: "15px",
  },
  contentContainer: {
    height: "78%",
    backgroundColor: colors.backgroundGraph,
    borderColor: colors.backgroundDark,
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    boxShadow: "0px 15px 10px -15px #111",
  },
  tooltipWrapper: {
    backgroundColor: colors.backgroundLight,
    alignItems: "center",
    textAlign: "center",
    height: "80px",
  },
  tooltipHeader: {
    fontWeight: "bold",
  },
}));
export default energyItemStyles;
