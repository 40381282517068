import { showNotification } from "@mantine/notifications";
import dayjs from "dayjs";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchReservationsByLocation,
  setPageIndexGlobalReservations,
  setPageSizeGlobalReservations,
  updateExpiredReservations,
} from "../../store/slices/reservationsSlice";
import TableGeneric from "../Table/Table";

const LocationsReservations = (props) => {
  const dispatch = useDispatch();

  const pageIndexReservations = useSelector(
    (state) => state.reservations.pageIndex
  );
  const pageSizeReservations = useSelector(
    (state) => state.reservations.pageSize
  );
  const getTotalPages = useSelector((state) => state.reservations.totalPages);
  const getAllReservations = useSelector(
    (state) =>
      state.reservations.allReservations._embedded?.reservationResources
  );

  const dispatchAllReservations = async () => {
    try {
      await dispatch(
        fetchReservationsByLocation({
          locationId: props?.locationId,
          page: pageIndexReservations,
          size: pageSizeReservations,
        })
      ).unwrap();
    } catch (error) {
      throw error;
    }
  };

  const dispatchUpdateExpiredReservations = async () => {
    try {
      await dispatch(updateExpiredReservations()).unwrap();
    } catch (err) {
      showNotification({
        title: "Greška",
        message: "Neočekivana greška",
        color: "red",
      });
    }
  };

  const renderReservationStatusTranslation = (status) => {
    switch (status) {
      case "USED":
        return "Iskorištena";
      case "CANCELLED":
        return "Otkazana";
      case "ACTIVE":
        return "Aktivna";
      case "EXPIRED":
        return "Istekla";
      case "IN_PROGRESS":
        return "U tijeku";
      default:
        return "";
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Korisnik",
        accessor: "userEmail",
      },
      {
        Header: "Datum punjenja",
        accessor: "startDate",
        Cell: (props) => {
          return props.value ? dayjs(props.value).format("DD.MM.YYYY.") : null;
        },
      },
      {
        Header: "Sat punjenja",
        accessor: "startTime",
        Cell: (props) => {
          return props.value ? props.value.slice(0, -3) : null;
        },
      },
      {
        Header: "Status",
        accessor: "reservationStatus",
        Cell: (props) => {
          return props.value
            ? renderReservationStatusTranslation(props.value)
            : null;
        },
      },
      {
        Header: "Kreirana",
        accessor: "createdAt",
        Cell: (props) => {
          return props.value
            ? dayjs(props.value).format("DD.MM.YYYY. HH:mm")
            : null;
        },
      },
    ],
    []
  );

  useEffect(() => {
    const initializePage = async () => {
      await dispatchUpdateExpiredReservations();
      dispatch(setPageIndexGlobalReservations(0));
      await dispatchAllReservations();
    };

    initializePage();
  }, []);

  return (
    <>
      <TableGeneric
        columns={columns}
        data={getAllReservations || []}
        totalPages={getTotalPages}
        manualPagination={true}
        manualPageIndex={pageIndexReservations}
        manualPageSize={pageSizeReservations}
        manualOnPageIndexChange={(pageIndex) => {
          dispatch(setPageIndexGlobalReservations(pageIndex));
          dispatch(
            fetchReservationsByLocation({
              locationId: props?.locationId,
              page: pageIndex,
              size: pageSizeReservations,
            })
          );
        }}
        manualOnPageSizeChange={(pageSize) => {
          dispatch(
            fetchReservationsByLocation({
              locationId: props?.locationId,
              size: pageSize,
              page: 0,
            })
          );
          dispatch(setPageSizeGlobalReservations(pageSize));
          dispatch(setPageIndexGlobalReservations(0));
        }}
      />
    </>
  );
};

export default LocationsReservations;
