import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RulesApi } from "../../api";

export const fetchAllRules = createAsyncThunk(
  "locations/getAllRules",
  async (args) => {
    return await RulesApi.getAllRules(args);
  }
);

export const updateRules = createAsyncThunk(
  "locations/updateRules",
  async (args) => {
    return await RulesApi.updateRules(args);
  }
);

export const saveRules = createAsyncThunk("locations/saveRules", async (args) => {
  return await RulesApi.saveRules(args);
});

export const deleteRules = createAsyncThunk(
  "locations/deleteRules",
  async (args) => {
    return await RulesApi.deleteRules(args);
  }
);

const initialState = {
  status: null,
  allRules: [],
  totalPages: 0,
  totalElements: 0,
  pageSize:10,
  pageIndex:0
};

const rulesSlice = createSlice({
  name: "rules",
  initialState,
  reducers: {
    setPageSizeGlobalRules: (state, action) => {
      console.log(action.payload);
      return { ...state, pageSize: action.payload };
    },
    setPageIndexGlobalRules: (state, action) => {
      console.log(action.payload);
      return { ...state, pageIndex: action.payload };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRules.pending, (state, action) => {
        return {
          ...state,
          status: "pending",
        };
      })
      .addCase(fetchAllRules.fulfilled, (state, action) => {
        return {
          ...state,
          status: "fulfilled",
          allRules: action.payload,
          totalPages: action.payload.page.totalPages,
          totalElements: action.payload.page.totalElements,
        };
      })
      .addCase(fetchAllRules.rejected, (state, action) => {
        return {
          ...state,
          status: "error",
        };
      })
      .addCase(updateRules.pending, (state, action) => {
        return {
          ...state,

          status: "pending",
        };
      })
      .addCase(updateRules.fulfilled, (state, action) => {
        return {
          ...state,

          status: "fulfilled",
        };
      })
      .addCase(updateRules.rejected, (state, action) => {
        return {
          ...state,
          status: "error",
        };
      })
      .addCase(saveRules.pending, (state, action) => {
        return {
          ...state,

          status: "pending",
        };
      })
      .addCase(saveRules.fulfilled, (state, action) => {
        return {
          ...state,

          status: "fulfilled",
        };
      })
      .addCase(saveRules.rejected, (state, action) => {
        return {
          ...state,
          status: "error",
        };
      });
  },
});

export const { setPageIndexGlobalRules,setPageSizeGlobalRules } = rulesSlice.actions;
export default rulesSlice.reducer;
