import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LocationsApi } from "../../api";

export const fetchAllLocations = createAsyncThunk(
  "locations/getAllLocations",
  async (args) => {
    return await LocationsApi.getAllLocations(args);
  }
);

export const updateLocations = createAsyncThunk(
  "locations/updateLocations",
  async (args) => {
    return await LocationsApi.updateLocations(args);
  }
);

export const saveLocations = createAsyncThunk(
  "locations/saveLocations",
  async (args) => {
    return await LocationsApi.saveLocations(args);
  }
);

export const deleteLocations = createAsyncThunk(
  "locations/deleteLocations",
  async (args) => {
    return await LocationsApi.deleteLocations(args);
  }
);

const initialState = {
  status: null,
  allLocations: [],
  totalPages: 0,
  totalElements: 0,
  rowData: null,
  screenType: "general",
};

const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    setRowData: (state, action) => {
    
      return { ...state, rowData: action.payload };
    },
    setScreenType: (state, action) => {
      
      return { ...state, screenType: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllLocations.pending, (state, action) => {
        return {
          ...state,
          status: "pending",
        };
      })
      .addCase(fetchAllLocations.fulfilled, (state, action) => {
        return {
          ...state,
          status: "fulfilled",
          allLocations: action.payload,
          totalPages: action.payload.page.totalPages,
          totalElements: action.payload.page.totalElements,
        };
      })

      .addCase(fetchAllLocations.rejected, (state, action) => {
        return {
          ...state,
          status: "error",
        };
      })
      .addCase(updateLocations.pending, (state, action) => {
        return {
          ...state,

          status: "pending",
        };
      })
      .addCase(updateLocations.fulfilled, (state, action) => {
        return {
          ...state,

          status: "fulfilled",
        };
      })
      .addCase(updateLocations.rejected, (state, action) => {
        return {
          ...state,
          status: "error",
        };
      })
      .addCase(saveLocations.pending, (state, action) => {
        return {
          ...state,
          status: "pending",
        };
      })
      .addCase(saveLocations.fulfilled, (state, action) => {
        return {
          ...state,
          status: "fulfilled",
        };
      })
      .addCase(saveLocations.rejected, (state, action) => {
        return {
          ...state,
          status: "error",
        };
      });
  },
});
export const { setRowData, setScreenType } = locationsSlice.actions;

export default locationsSlice.reducer;
