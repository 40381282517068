import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setScreenType } from "../../store/slices/locationsSlice";
import { useOutletContext } from "react-router-dom";
import TableGeneric from "../Table/Table";
import {
  fetchAllChargers,
  setPageIndexGlobalChargers,
  setPageSizeGlobalChargers,
} from "../../store/slices/chargersSlice";
import { Button, Group } from "@mantine/core";
import LocationsChargersModal from "./LocationsChargersModal";
import { format, parseISO } from "date-fns";
import dayjs from "dayjs";

const LocationsChargers = (props) => {
  const dispatch = useDispatch();

  const [rowData] = useOutletContext();

  const locationId = rowData.id;

  const pageIndexChargers = useSelector((state) => state.chargers.pageIndex);
  const pageSizeChargers = useSelector((state) => state.chargers.pageSize);

  const [showLocationsChargersModal, setShowLocationsChargersModal] =
    useState(false);
  const [showLocationsChargersModalData, setShowLocationsChargersModalData] =
    useState(null);

  const getTotalPages = useSelector((state) => state.chargers.totalPages);

  const getAllChargers = useSelector(
    (state) => state.chargers.allChargers?._embedded?.chargingStationResources
  );

  const dispatchAllChargers = async () => {
    try {
      await dispatch(
        fetchAllChargers({
          locationId: locationId,
          page: pageIndexChargers,
          size: pageSizeChargers,
        })
      ).unwrap();
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    dispatch(setScreenType("chargers"));
    dispatch(setPageIndexGlobalChargers(0));
    dispatchAllChargers();
  }, []);

  const openLocationChargersModalData = (data) => {
    setShowLocationsChargersModalData(data);

    setShowLocationsChargersModal(true);
  };

  const closeLocationChargersModalData = () => {
    setShowLocationsChargersModal(false);
    setShowLocationsChargersModalData(null);
  };

  const columns = useMemo(
    () => [
      {
        Header: "UUID",
        accessor: "uuid",
      },
      {
        Header: "Naziv",
        accessor: "name",
      },

      {
        Header: "Status",
        accessor: (row) => {
          return row.activity === "ACTIVE" ? "Aktivna" : "Neaktivna";
        },
      },
      {
        Header: "Kreiran",
        accessor: "createdAt",
        Cell: (props) => {
          return props.value
            ? dayjs(props.value).format("DD.MM.YYYY. HH:mm")
            : null;
        },
      },
      {
        Header: "",
        accessor: "id",
        Cell: (props) => {
          const data = props.row.original;

          return (
            <>
              <Group position="right">
                <Button
                  color={"green"}
                  size="sm"
                  onClick={() => {
                    openLocationChargersModalData(data);
                  }}
                >
                  {"Promijeni"}
                </Button>
              </Group>
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <TableGeneric
        columns={columns}
        data={getAllChargers || []}
        totalPages={getTotalPages}
        manualPagination={true}
        manualPageIndex={pageIndexChargers}
        manualPageSize={pageSizeChargers}
        manualOnPageIndexChange={(pageIndex) => {
          // setPageIndex(pageIndex);
          dispatch(setPageIndexGlobalChargers(pageIndex));
          dispatch(
            fetchAllChargers({
              locationId: locationId,
              page: pageIndex,
              size: pageSizeChargers,
            })
          );
        }}
        manualOnPageSizeChange={(pageSize) => {
          dispatch(
            fetchAllChargers({
              locationId: locationId,
              size: pageSize,
              page: 0,
            })
          );
          // setPageSize(pageSize);
          dispatch(setPageSizeGlobalChargers(pageSize));
          dispatch(setPageIndexGlobalChargers(0));
          //setPageIndex(0)
        }}
      />
      <LocationsChargersModal
        show={showLocationsChargersModal}
        data={showLocationsChargersModalData}
        close={closeLocationChargersModalData}
        page={pageIndexChargers}
        size={pageSizeChargers}
        locationId={locationId}
      />
    </>
  );
};

export default LocationsChargers;
