import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createOperator, updateOperator } from "../../api/operator";
import { fetchOperatorsPageable } from "../../api/operator";
import { fetchOperatorById } from "../../api/operator";

export const createOperatorThunk = createAsyncThunk(
  "operator/createOperator",
  async (args) => {
    return await createOperator(args);
  }
);

export const updateOperatorThunk = createAsyncThunk(
  "operator/createOperator",
  async (args) => {
    return await updateOperator(args);
  }
);

export const fetchOperators = createAsyncThunk(
  "operator/fetchOperators",
  async (args) => {
    return await fetchOperatorsPageable(args);
  }
);

export const fetchOperatorByIdThunk = createAsyncThunk(
  "operator/fetchtOperatorById",
  async (args) => {
    return fetchOperatorById(args);
  }
);

export const clearOperatorState = createAsyncThunk(
  "operator/clearOperatorState",
  async (args) => {
    return "";
  }
);

const initialState = {
  operator: {
    email: "",
    person: {
      firstName: "",
      lastName: "",
    },
    activity: "",
  },
  tableData: [],
  totalPages: 0,
  totalElements: 0,
};

const operatorSlice = createSlice({
  name: "operator",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createOperatorThunk.fulfilled, (state, action) => {
      //console.log(action.payload);
    });
    builder.addCase(clearOperatorState.fulfilled, (state, action) => {
      return { ...state, operator: initialState.operator };
    });
    builder.addCase(fetchOperators.fulfilled, (state, action) => {
      var data = [];
      try {
        data = action.payload._embedded.userAccountResources;
      } catch (e) {}
      return {
        ...state,
        tableData: data,
        totalElements: action.payload.page.totalElements,
        totalPages: action.payload.page.totalPages,
      };
    });
    builder.addCase(fetchOperatorByIdThunk.fulfilled, (state, action) => {
      return {
        ...state,
        operator: action.payload,
      };
    });
  },
});
export default operatorSlice.reducer;
