import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getOrganizationById,
  updateOrganization,
  updateOrganizationCertificate,
  getMainOrganization,
} from "../../api/organization";

export const updateOrganizationThunk = createAsyncThunk(
  "organization/updateOrganization",
  async (args) => {
    return await updateOrganization(args.id, args.args);
  }
);

export const fetchOrganizationByIdThunk = createAsyncThunk(
  "organization/fetchOrganizationById",
  async (id) => {
    return await getOrganizationById(id);
  }
);

export const updateCertificateThunk = createAsyncThunk(
  "organization/updateCert",
  async (args) => {
    return await updateOrganizationCertificate(
      args.id,
      args.password,
      args.alias,
      args.formData
    );
  }
);

export const fetchMainOrganization = createAsyncThunk(
  "organization/fetchMainOrganization",
  async () => {
    const organization = await getMainOrganization();
    return organization;
  }
);

const initialState = {
  organization: {
    name: "",
    pin: "",
    contactMail: "",
    adress: "",
    contactPhone: "",
    county: "",
    postalCode: "",
  },
};

const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchOrganizationByIdThunk.fulfilled,
      (state, action) => {}
    );
    builder.addCase(updateOrganizationThunk.fulfilled, (state, action) => {});
  },
});

export default organizationSlice.reducer;
