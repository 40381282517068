


import { createStyles } from "@mantine/core";

const locationsRulesModalDeleteStyles = createStyles((theme) => ({
  modalContainer: {
    background: "#53BDFF",
    padding: 0,
  },

  headerText: {
    color: "white",
    fontSize: 40,
  },
  bodyText: {
    color: "white",
    fontSize: 20,
    paddingTop: 20,
  },

  input: {
    borderRadius: "0px",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    width: "100%",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "40px",
    paddingBottom: "20px",
  },
}));
export default locationsRulesModalDeleteStyles;
