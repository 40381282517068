import { createStyles } from "@mantine/core";
import { colors } from "../../constants/colors";
const dashboardStyles = createStyles((theme) => ({
  userHeader: {
    backgroundColor: colors.backgroundDark,
    height: 40,
    width: "20vh",
    color: colors.textWhite,
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    border: "1px solid",
    borderColor: colors.backgroundDark,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },
  divider: {
    backgroundColor: colors.backgroundDark,
    height: 20,
    width: "100%",
    borderTopRightRadius: "15px",
  },
  dashboardContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  itemsContainer: {
    display: "flex",
    flexDirection: "row",
    height: "42vh",
    justifyContent: "space-around",
  },
  rowItemLeft: {
    flex: 6,
    margin: "10px",
  },
  rowItemRight: {
    flex: 4,
    margin: "10px",
  },
}));
export default dashboardStyles;
