import { Button, Tabs } from "@mantine/core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext, useParams } from "react-router-dom";
import { colors } from "../../constants/colors";
import { setScreenType } from "../../store/slices/locationsSlice";
import Transactions from "../Transactions/Transactions";
import LocationsChargers from "./LocationsChargers";
import LocationsGeneralEdit from "./LocationsGeneralEdit";
import locationsGeneralStyles from "./LocationsGeneralStyles";
import LocationsReservations from "./LocationsReservations";
import LocationsRules from "./LocationsRules";

const StyledTabs = (props) => {
  let params = useParams();
  return (
    <Tabs
      variant="unstyled"
      styles={(theme) => ({
        tabControl: {
          width: "18%",
          backgroundColor: colors.backgroundDark,
          color: colors.textWhite,
          border: "0px",
          padding: `${theme.spacing.lg}px ${theme.spacing.xl}px`,
        },
        tabActive: {
          backgroundColor: colors.tabActive,
          color: colors.textWhite,
        },
        body: { padding: 0 },
      })}
      {...props}
    />
  );
};

const LocationsGeneral = (props) => {
  const dispatch = useDispatch();

  const params = useParams();
  useEffect(() => {}, []);

  const { classes } = locationsGeneralStyles();

  return (
    <>
      <div className={classes.divider}>
        <div
          style={{ width: "18%", textAlign: "center", color: "white" }}
        ></div>
        <StyledTabs>
          <Tabs.Tab label="OPĆENITO">
            <LocationsGeneralEdit />
          </Tabs.Tab>
          <Tabs.Tab label="PUNIONICE">
            <LocationsChargers />
          </Tabs.Tab>
          <Tabs.Tab label="PRAVILA">
            <LocationsRules/>
          </Tabs.Tab>
          <Tabs.Tab label="TRANSAKCIJE">
            <Transactions locationId={params.locationsId} />
          </Tabs.Tab>
          <Tabs.Tab label="REZERVACIJE">
            <LocationsReservations locationId={params.locationsId}/>
          </Tabs.Tab>
        </StyledTabs>
      </div>
    </>
  );
};

export default LocationsGeneral;
