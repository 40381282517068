import { createStyles } from "@mantine/core";
import { colors } from "../../constants/colors";

const TrasactionStyles = createStyles((theme) => ({
  transactionHeader: {
    backgroundColor: colors.backgroundDark,
    height: 40,
    width: "18%",
    color: colors.textWhite,
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    border: "1px solid",
    borderColor: colors.backgroundDark,
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },
  divider: {
    backgroundColor: colors.backgroundDark,
    height: 20,
    width: "100%",
    borderTopRightRadius: "15px",
  },
  tableContainer: {
    height: "68vh",
    backgroundColor: colors.backgroundGraph,
    borderColor: colors.backgroundDark,
    borderTopRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    overflowY: "scroll",
    "::-webkit-scrollbar": {
      backgroundColor: colors.backgroundDark,
    },
    "::-webkit-scrollbar": {
      width: "15px",
      marginLeft: "30px",
    },
    "::-webkit-scrollbar-thumb": {
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px",
      backgroundColor: colors.backgroundDark,
      border: "1px solid black",
    },
    "::-webkit-scrollbar-track-piece ": {
      backgroundColor: colors.tableHeaderDark,
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
    "::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
  },
  datePickerFormContainer: {
    height: "60px",
    width: "60%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  datePicker: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "sta",
    width: "30%",
  },
  datePickerLabel: {
    fontWeight: "bold",
    paddingLeft: "10px",
    paddingRight: "10px",
    color: colors.textWhite,
  },
  datePickerInput: {
    color: colors.backgroundDark,
    textAlign: "center",
    border: "1px solid",
    borderColor: colors.textWhite,
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "0px",
    borderBottomLeftRadius: "0px",
  },
  datePickerRoot: {
    border: "1px solid",
    borderColor: "white",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    borderBottomRightRadius: "0px",
    borderBottomLeftRadius: "0px",
  },
}));
export default TrasactionStyles;
