import React, { useEffect, useMemo, useState } from "react";
import usersStyles from "./UsersStyles";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOperators,
  fetchOperatorByIdThunk,
  updateOperatorThunk,
} from "../../store/slices/operatorSlice";
import { Button, Dialog, Overlay, Select } from "@mantine/core";
import TableGeneric from "../Table/Table";
import { AlertCircle } from "tabler-icons-react";
import { colors } from "../../constants/colors";
import OperatorsModal from "../Organizations/OperatorsModal";
import { useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import * as dayjs from "dayjs";
const Users = () => {
  const { classes } = usersStyles();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const tableData = useSelector((state) => state.operator.tableData);
  const getTotalPages = useSelector((state) => state.operator.totalPages);
  const [dialogOpened, setDialogOpened] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [updateId, setUpdateId] = useState("0");
  const [status, setStatus] = useState(null);
  const [deleteId, setDeleteId] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteButton = (id) => {
    return (
      <Button
        color={"green"}
        size="sm"
        onClick={() => {
          setDialogOpened(true);
          setDeleteId(id);
        }}
      >
        Izbriši osobne podatke
      </Button>
    );
  };

  const changeButton = (id) => {
    return (
      <Button
        color={"green"}
        size="sm"
        onClick={() => {
          setUpdateId(id);
          dispatch(fetchOperatorByIdThunk(id));
          setModalOpened(true);
        }}
      >
        Promijeni
      </Button>
    );
  };

  const transactionButton = (id) => {
    return (
      <Button
        color={"green"}
        size="sm"
        onClick={() => navigate("transactions/" + id)}
      >
        Transakcije
      </Button>
    );
  };

  const deleteUserData = () => {
    dispatch(
      updateOperatorThunk({
        person: {
          id: deleteId,
          lastName: "",
          firstName: "",
          countryId: "",
          city: "",
          adress: "",
        },
        activity: "INACTIVE",
        termsAndConditionsConfirmed: true,
        privacyPolicyConfirmed: true,
      })
    )
      .unwrap()
      .then(() => {
        showNotification({
          message: "Brisanje osobnih podataka uspješno!",
          color: "green",
          autoClose: 3000,
        });
        fetchAllOperators(pageIndex, pageSize);
      })
      .catch((e) => {
        showNotification({
          message: "Greška kod brisanja osobnih podataka!",
          color: "red",
          autoClose: 3000,
        });
      });
  };

  const dataRows = useMemo(() => {
    return tableData.map(({ person, email, id, createdAt }) => {
      const firstName = person?.firstName ? person.firstName : "";
      const lastName = person?.lastName ? person.lastName : "";
      return {
        name: firstName + " " + lastName,
        email: email,
        created: dayjs(createdAt).format("DD.MM.YYYY HH:mm"),
        delete: deleteButton(id),
        change: changeButton(id),
        transactions: transactionButton(id),
      };
    });
  }, [tableData]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = useMemo(
    () => [
      {
        Header: "IME I PREZIME",
        accessor: "name",
      },
      {
        Header: "EMAIL",
        accessor: "email",
      },
      {
        Header: "KREIRAN",
        accessor: "created",
      },
      {
        Header: "",
        accessor: "delete",
      },
      {
        Header: "",
        accessor: "change",
      },
      {
        Header: "",
        accessor: "transactions",
      },
    ],
    []
  );

  const closeModal = () => {
    setModalOpened(false);
    fetchAllOperators(pageIndex, pageSize);
  };
  const fetchAllOperators = (page, size) => {
    var activity = status;
    var userAccountType = "USER";
    dispatch(
      fetchOperators({
        activity,
        userAccountType,
        page,
        size,
      })
    );
  };
  useEffect(() => {
    fetchAllOperators(pageIndex, pageSize);
  }, [modalOpened, status, dialogOpened]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      {dialogOpened && <Overlay opacity={0.6} color="#000" zIndex={5} />}
      <div className={classes.userHeader}>
        <span>Korisnici</span>
      </div>
      <div className={classes.divider}></div>
      <TableGeneric
        columns={columns}
        data={dataRows}
        totalPages={getTotalPages}
        manualPagination={true}
        manualPageIndex={pageIndex}
        manualPageSize={pageSize}
        manualOnPageIndexChange={(pageIndex) => {
          setPageIndex(pageIndex);
          fetchAllOperators(pageIndex, pageSize);
        }}
        manualOnPageSizeChange={(pageSize) => {
          setPageSize(pageSize);
          fetchAllOperators(0, pageSize);
        }}
      />
      <Select
        classNames={{
          root: classes.dropdownRoot,
          dropdown: classes.dropDown,
          defaultVariant: classes.dropDownFilled,
          input: classes.dropDownInput,
          wrapper: classes.dropdownWrapper,
          selected: classes.dropdownSelected,
          hovered: classes.dropDownHovered,
          item: classes.dropDownItem,
        }}
        data={[
          { value: null, label: "Svi" },
          { value: "ACTIVE", label: "Aktivni" },
          { value: "INACTIVE", label: "Neaktivni" },
        ]}
        value={status}
        onChange={(e) => {
          setPageIndex(0);
          setStatus(e);
        }}
      />
      <Dialog
        position={{ top: "40%", left: "40%" }}
        opened={dialogOpened}
        onClose={() => setDialogOpened(false)}
        size="lg"
        radius="md"
        classNames={{ root: classes.dialog }}
      >
        <div className={classes.dialogContainer}>
          <div className={classes.headingContainer}>
            <AlertCircle size={30} strokeWidth={2} color={colors.buttonRed} />
            <span>Potvrdite brisanje osobnih podataka</span>
          </div>

          <div className={classes.buttonsContainer}>
            <Button
              className={classes.confirmButton}
              onClick={() => {
                deleteUserData();
                setDialogOpened(false);
              }}
            >
              Da
            </Button>
            <Button
              className={classes.cancelButton}
              onClick={() => {
                setDialogOpened(false);
                fetchAllOperators(pageIndex, pageSize);
              }}
            >
              Otkaži
            </Button>
          </div>
        </div>
      </Dialog>
      <OperatorsModal
        userType="Korisnik"
        updateId={updateId}
        opened={modalOpened}
        onClose={closeModal}
        fetchAllOperators={fetchAllOperators}
        pageSize={pageSize}
      />
    </>
  );
};

export default Users;
