import { createStyles } from "@mantine/core";
import { colors } from "../../constants/colors";

const tableStyles = createStyles((theme) => ({
  paginationItem: {
    backgroundColor: "transparent",
    borderColor: "transparent",
    color: colors.paginationInactive,
  },
  paginationActive: {
    backgroundColor: colors.backgroundDark,
    borderColor: colors.backgroundDark,
    color: colors.textWhite,
  },
  pageSelect: {
    backgroundColor: colors.backgroundLight,
    borderColor: "transparent",
    color: colors.paginationInactive,
  },
  pageSelectTransparent: {
    backgroundColor: colors.backgroundGraph,
    borderColor: "transparent",
    color: colors.paginationInactive,
  },
  pageSelectionPageSize: {
    color: "red",
  },
  pageSelectOption: {
    backgroundColor: "transparent",
    color: colors.textWhite,
  },
  pageSelectOptionTransparent: {
    backgroundColor: "transparent",
    color: colors.textWhite,
  },
  paginationGroup: {
    backgroundColor: "red",
    float: "right",
  },
  tableContainer: {
    maxHeight: 700,
    overflowY: "auto",

    [`@media (max-width: 1600px)`]: {
      maxHeight: 350,
    },

    "::-webkit-scrollbar": {
      backgroundColor: colors.backgroundDark,
    },
    "::-webkit-scrollbar": {
      width: "15px",
      marginLeft: "30px",
    },
    "::-webkit-scrollbar-thumb": {
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
      backgroundColor: colors.backgroundDark,
      border: "1px solid black",
    },
    "::-webkit-scrollbar-track-piece ": {
      backgroundColor: colors.tableHeaderDark,
      borderBottomRightRadius: "10px",
      borderTopRightRadius: "10px",
    },
    "::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
  },
}));
export default tableStyles;
