import dashboardStyles from "./DashboardStyles";
import EnergyItem from "./EnergyItem";
import ReservationsGraphItem from "./ReservationsGraphItem";
import ReservationsItem from "./ReservationsItem";
import StatisticsItem from "./StatisticsItem";

const Dashboard = () => {
  const { classes } = dashboardStyles();

  return (
    <>
      <div className={classes.dashboardContainer}>
        <div className={classes.itemsContainer}>
          <div className={classes.rowItemLeft}>
            <StatisticsItem />
          </div>
          <div className={classes.rowItemRight}>
            <ReservationsGraphItem />
          </div>
        </div>
        <div className={classes.itemsContainer}>
          <div className={classes.rowItemLeft}>
            <ReservationsItem />
          </div>
          <div className={classes.rowItemRight}>
            <EnergyItem />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
