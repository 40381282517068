import axios from "./axios";

export const getReservationsForDashboard = async (args) => {
  var response = await axios.get(
    "/api/v1/dashboard/reservations/by-locations",
    { params: args }
  );
  return response.data;
};

export const getStatisticsForDashboard = async (args) => {
  var response = await axios.get(
    "/api/v1/dashboard/transactions-amount/by-locations",
    { params: args }
  );
  return response.data;
};

export const getReservationsGraphData = async () => {
  var response = await axios.get(
    "/api/v1/dashboard/reservations-count/by-days"
  );
  return response.data;
};

export const getEnergyGraphData = async () => {
  var response = await axios.get("/api/v1/dashboard/energy-spent/by-days");
  return response.data;
};
