import React, { useMemo, useState } from "react";
import { useSortBy, usePagination, useTable } from "react-table";
import { Group, Pagination, Select, Table } from "@mantine/core";
import { useColorScheme } from "@mantine/hooks";
import { useSelector } from "react-redux";
import TableScrollbar from "react-table-scrollbar";
import tableStyles from "./tableStyles";
export const TableGeneric = ({
  multipleTablesOnScreen,
  columns,
  data,
  totalPages,
  manualPagination = false,
  manualPageIndex,
  manualPageSize,
  manualOnPageIndexChange,
  manualOnPageSizeChange,
  onRowClick,
  customTableHeight,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    rows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    manualPagination
      ? {
          columns,
          data,
          initialState: { pageIndex: 0, pageSize: 10 },
          pageCount: totalPages,
          manualPagination: manualPagination,
          useControlledState: (state) =>
            useMemo(() => {
              return {
                ...state,
                pageIndex: manualPageIndex,
                pageSize: manualPageSize,
              };
            }, [state, manualPagination, manualPageIndex, manualPageSize]),
        }
      : {
          columns,
          data,
          initialState: { pageIndex: 0, pageSize: 10 },
        },
    usePagination
  );

  const { classes } = tableStyles();

  const PaginationElements = () => {
    return (
      <>
        <Group position="right">
          <span>
            Str:{" "}
            <strong>
              {pageIndex + 1 > pageOptions.length ? pageIndex : pageIndex + 1}{" "}
              od {pageOptions.length === 0 ? 0 : pageOptions.length}
            </strong>{" "}
          </span>
          <form
            value={pageSize}
            onChange={(e) => {
              if (manualPagination) {
                manualOnPageSizeChange(Number(e.target.value));
              } else {
                setPageSize(Number(e.target.value));
                console.log(e.target.value);
              }
            }}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Pagination
                classNames={{
                  active: classes.paginationActive,
                  item: classes.paginationItem,
                }}
                withControls={totalPages}
                total={totalPages}
                page={pageIndex + 1}
                siblings={1}
                boundaries={2}
                onChange={(pageNum) => {
                  manualOnPageIndexChange(pageNum - 1);
                }}
              />
              {multipleTablesOnScreen ? (
                <select
                  className={classes.pageSelectTransparent}
                  defaultValue={pageSize}
                >
                  {[10, 25, 50].map((pageSize) => (
                    <option
                      className={classes.pageSelectOptionTransparent}
                      key={pageSize}
                      value={pageSize}
                    >
                      Redova po stranici {pageSize}
                    </option>
                  ))}
                </select>
              ) : (
                <select className={classes.pageSelect} defaultValue={pageSize}>
                  {[10, 25, 50].map((pageSize) => (
                    <option
                      className={classes.pageSelectOption}
                      key={pageSize}
                      value={pageSize}
                    >
                      Redova po stranici {pageSize}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </form>
        </Group>
      </>
    );
  };

  // Render the UI for your table
  return (
    <>
      <div
        style={{ height: customTableHeight }}
        className={classes.tableContainer}
      >
        <Table
          {...getTableProps()}
          sx={() => ({
            "thead tr th": {
              color: "white",
              backgroundColor: "#102A43",
              padding: 10,
              borderTop: "rgb(16, 20, 36)",
            },
          })}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody style={{ color: "white" }} {...getTableBodyProps()}>
            {rows.length ? (
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    onClick={() =>
                      typeof onRowClick === "function" &&
                      onRowClick(row?.original)
                    }
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td style={{ textAlign: "center" }} colSpan={columns.length}>
                  {"Nema podataka"}
                </td>
              </tr>
            )}
          </tbody>
        </Table>{" "}
      </div>

      {multipleTablesOnScreen ? (
        <div>
          <PaginationElements />
        </div>
      ) : (
        <div style={{ position: "fixed", bottom: 10, right: 25 }}>
          <PaginationElements />
        </div>
      )}
    </>
  );
};

export default TableGeneric;
