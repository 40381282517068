import axios from "./axios";

/*export const authenticate = async (data) => {
  return { isLoggedIn: data };
};*/

export const authenticate = async (credentials) => {
  const response = await axios.post(
    "/api/v1/authorization-codes",
    credentials,
    {
      skipAuthRefresh: true,
    }
  );

  return { statusCode: response.status, credentials };
};


