import axios from "./axios";

export const getReservationsByLocation = async (args) => {
  const response = await axios.get(
    `/api/v1/reservations/locations/${args.locationId}?page=${args.page}&size=${args.size}&sort=createdAt`
  );
  return response.data;
};

export const patchExpiredReservations = async () => {
  await axios.patch("/api/v1/reservations/expired");
};
