import { useEffect, useState } from "react";
import { Tabs } from "@mantine/core";
import OrganizationsGeneral from "./OrganizationsGeneral";
import OrganizationsOperators from "./OrganizationsOperators";
import organizationStyles from "./OrganizationStyles";
import { colors } from "../../constants/colors";
import { useNavigate } from "react-router-dom";
import Transactions from "../Transactions/Transactions";
import { useDispatch } from "react-redux";
import { fetchMainOrganization } from "../../store/slices/organizationSlice";

const Organizations = () => {
  const { classes } = organizationStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [organizationId, setOrganizationId] = useState("");

  useEffect(() => {
    const dispatchFetchMainOrganization = async () => {
      try {
        const organization = await dispatch(fetchMainOrganization()).unwrap();
        setOrganizationId(organization.id);
        return organization;
      } catch (error) {}
    };
    dispatchFetchMainOrganization();
  }, []);

  const StyledTabs = (props) => {
    return (
      <Tabs
        variant="unstyled"
        classNames={{ body: classes.tabRoot }}
        sx={() => ({
          ".mantine-1opdmpb": {
            paddingTop: 0,
          },
          ".mantine-1u25t5n": {
            borderBottom: 0,
          },
        })}
        styles={(theme) => ({
          tabControl: {
            width: "18%",
            backgroundColor: colors.backgroundDark,
            color: colors.textWhite,
            border: "0px",
            padding: `${theme.spacing.lg}px ${theme.spacing.xl}px`,
          },
          tabActive: {
            backgroundColor: colors.tabActive,
            color: colors.textWhite,
          },
        })}
        {...props}
      />
    );
  };
  return (
    <>
      <div className={classes.organizationHeader}>
        <span>Organizacija</span>
      </div>
      <div className={classes.divider}></div>
      <StyledTabs>
        <Tabs.Tab label="OPĆENITO">
          <OrganizationsGeneral />
        </Tabs.Tab>
        <Tabs.Tab label="OPERATERI">
          <OrganizationsOperators />
        </Tabs.Tab>
        <Tabs.Tab label="TRANSAKCIJE">
          <Transactions organizationId={organizationId} />
        </Tabs.Tab>
      </StyledTabs>
    </>
  );
};

export default Organizations;
