



export const otpButtonStyles = () => ({
  root: {
    marginTop: "5px",
    backgroundColor: "transparent",
  },
  outline: {
    color: "blue",
  },
});

export const otpInputStyle = {
  backgroundColor: "lightblue",
  height: "6rem",
  width: "3rem",
  fontSize: "2rem",
  borderRadius: "10px",
  margin: "0px 5px",
  alignItems: "center",
};

export const otpContainer = {
  justifyContent: "center",
  width: "100%",
  alignItems: "center",
  textAlign: "center",
  alignContent: "center",
};
