import axios from "./axios";

export const getTransactionsFromUser = async (args) => {
  var response = await axios.get("/api/v1/transactions/users/" + args.id, {
    params: { ...args, sort: "createdAt" },
  });
  return response.data;
};

export const getTransactionsFromOrganization = async (args) => {
  var response = await axios.get("/api/v1/transactions", {
    params: { ...args, sort: "createdAt" },
  });
  return response.data;
};

export const getTransactionsFromLocation = async (args) => {
  var response = await axios.get("/api/v1/transactions/locations/" + args.id, {
    params: { ...args, sort: "createdAt" },
  });
  return response.data;
};

export const getExportDataForOrganization = async (args) => {
  var response = await axios.get("/api/v1/transactions/export", {
    params: { ...args, sort: "createdAt" },
    responseType: "blob",
  });
  return response.data;
};

export const getExportDataForUser = async (args) => {
  var response = await axios.get(
    "/api/v1/transactions/users/" + args.id + "/export",
    {
      params: { ...args, sort: "createdAt" },
      responseType: "blob",
    }
  );
  return response.data;
};

export const getExportDataForLocation = async (args) => {
  var response = await axios.get(
    "/api/v1/transactions/locations/" + args.id + "/export",
    {
      params: { ...args, sort: "createdAt" },
      responseType: "blob",
    }
  );
  return response.data;
};
