import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { Alert, Button, InputWrapper, Notification } from "@mantine/core";
import { otpButtonStyles, otpContainer, otpInputStyle } from "./otpInputStyles";
import { useDispatch, useSelector } from "react-redux";
import { sendOtp } from "../../store/slices/otpSlice";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { showNotification } from "@mantine/notifications";

export const OTPInput = (props) => {
  const [OTP, setOTP] = useState("");
  const [err, setErr] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.otpAuth.accessToken);
  const otpStatus = useSelector((state) => state.otpAuth.status);
  const statusCode = useSelector((state) => state.otpAuth.statusCode);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const dispatchOtp = async (otp) => {
    try {
      setOTP(otp);
      otp.length === 6 &&
        (await dispatch(
          sendOtp({ authorizationCode: otp, email: props.email })
        ));

      otp.length === 6 && setOTP("");
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    otpStatus === "fulfilled" && navigate("/");
    {
      statusCode === 401 &&
        showNotification({
          message: "Unijeli ste krivi OTP",
          color: "red",
        });
    }
  }, [statusCode, otpStatus]);

  return (
    <div>
      <div>
        <OtpInput
          value={OTP}
          onChange={dispatchOtp}
          shouldAutoFocus={true}
          numInputs={6}
          inputStyle={otpInputStyle}
          containerStyle={otpContainer}
          isInputNum={false}
          hasErrored={false}
          errorStyle={""}
          isDisabled={otpStatus === "pending"}
        />
      </div>

      <Button
        styles={otpButtonStyles}
        variant="subtle"
        onClick={async () => {
          await props.dispatchAuthenticate({ email: props.email });

          await showNotification({
            message: "OTP poslan na email",
            color: "green",
          });
        }}
      >
        {"Pošalji OTP ponovno"}
      </Button>
    </div>
  );
};

export default OTPInput;
