import { Button, InputWrapper, TextInput, Image } from "@mantine/core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import organizationsGeneralStyles from "./OrganizationsGeneralStyles";
import {
  updateOrganizationThunk,
  fetchOrganizationByIdThunk,
  updateCertificateThunk,
  fetchMainOrganization,
} from "../../store/slices/organizationSlice";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import images from "../../constants/images";
import { showNotification } from "@mantine/notifications";

const OrganizationsGeneral = () => {
  const { classes } = organizationsGeneralStyles();
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const organization = "2";
  const [cert, setCert] = useState();
  const [isCertUploaded, setIsCertUploaded] = useState(false);
  const formData = new FormData();

  const dispatchFetchMainOrganization = async () => {
    try {
      const organization = await dispatch(fetchMainOrganization()).unwrap();
      return organization;
    } catch (error) {}
  };

  const onSubmit = async (data) => {
    const { id } = await dispatchFetchMainOrganization();
    dispatch(
      updateOrganizationThunk({
        id: id,
        args: {
          id: id,
          name: data.name,
          pin: data.pin,
          contactEmail: data.contactEmail,
          address: data.address,
          contactPhone: data.contactPhone,
          county: data.county,
          postalCode: data.postalCode,
          receiptLiquidator: data.receiptLiquidator,
          receiptOperator: data.receiptOperator,
          businessPremisesId: data.businessPremisesId,
          paymentDeviceId: data.paymentDeviceId,
          vatRate: data.vatRate,
        },
      })
    )
      .unwrap()
      .then(() => {
        showNotification({
          message: "Organizacija je uspješno ažurirana!",
          color: "green",
        });
      })
      .catch((e) => {
        showNotification({
          message: "Greška kod unosa organizacije!",
          color: "red",
        });
      });
    if (isCertUploaded) {
      formData.append("certificate", cert, cert.name);
      dispatch(
        updateCertificateThunk({
          id: id,
          formData: formData,
          password: data.password,
        })
      )
        .unwrap()
        .then(() => {
          showNotification({
            message: "Certifikat je uspješno ažuriran!",
            color: "green",
            autoClose: 3000,
          });
        })
        .catch((e) => {
          showNotification({
            message: "Greška kod unosa certifikata!",
            color: "red",
            autoClose: 3000,
          });
        });
    }
    setIsCertUploaded(false);
    setCert(null);
  };

  const changeHandler = (event) => {
    if (
      event.target.files[0].type === "application/x-x509-ca-cert" ||
      event.target.files[0].type === "application/x-pkcs12"
    ) {
      setCert(event.target.files[0]);
      setIsCertUploaded(true);
    } else {
      return;
    }
  };
  const schema = yup.object().shape({
    name: yup.string().min(1, "Morate unijeti naziv!"),
    address: yup.string().min(1, "Morate unijeti adresu!"),
    contactEmail: yup
      .string()
      .required("Morate unijeti email adresu!")
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Unesite email ispravnog formata!"
      ),
    contactPhone: yup.string().min(1, "Morate unijeti broj telefona!"),
    county: yup.string().min(1, "Morate unijeti mjesto!"),
    pin: yup
      .string()
      .min(11, "OIB nije ispravnog formata!")
      .max(11, "OIB nije ispravnog formata!"),
    postalCode: yup.string().min(1, "Morate unijeti poštanski broj!"),
    receiptOperator: yup.string().min(1, "Morate unijeti oznaku operatera!"),
    receiptLiquidator: yup
      .string()
      .min(1, "Morate unijeti oznaku likvidatora!"),
    businessPremisesId: yup
      .string()
      .min(1, "Morate unijeti oznaku poslovnog prostora!"),
    paymentDeviceId: yup.string().min(1, "Morate unijeti oznaku terminala!"),
    vatRate: yup.string().min(1, "Morate unijeti poreznu stopu!"),
  });

  const {
    reset,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    dispatch(fetchOrganizationByIdThunk(organization))
      .unwrap()
      .then((data) => {
        setValue("address", data.address);
        setValue("contactEmail", data.contactEmail);
        setValue("contactPhone", data.contactPhone);
        setValue("county", data.county);
        setValue("id", data.id);
        setValue("name", data.name);
        setValue("pin", data.pin);
        setValue("postalCode", data.postalCode);
        setValue("receiptOperator", data.receiptOperator);
        setValue("receiptLiquidator", data.receiptLiquidator);
        setValue("businessPremisesId", data.businessPremisesId);
        setValue("paymentDeviceId", data.paymentDeviceId);
        setValue("vatRate", data.vatRate);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getWindowSize = () => {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  };
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ overflow: "hidden" }}>
        <div className={classes.form}>
          <InputWrapper
            classNames={{
              root: classes.root,
              label: classes.label,
            }}
            label={"PUNI NAZIV"}
            required
            style={
              windowSize.innerWidth < 1550
                ? {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }
                : null
            }
          >
            <TextInput
              classNames={{
                root: classes.wrapper,
                defaultVariant: classes.filledVariant,
                input: classes.input,
              }}
              {...register("name")}
              error={errors.name && errors.name.message}
            />
          </InputWrapper>
          <InputWrapper
            classNames={{
              root: classes.root,
              label: classes.label,
            }}
            style={
              windowSize.innerWidth < 1550
                ? {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }
                : { paddingLeft: "5%" }
            }
            label={"OIB"}
            required
          >
            <TextInput
              classNames={{
                root: classes.wrapper,
                defaultVariant: classes.filledVariant,
                input: classes.input,
              }}
              {...register("pin")}
              error={errors.pin && errors.pin.message}
            />
          </InputWrapper>
          <InputWrapper
            classNames={{
              root: classes.root,
              label: classes.label,
            }}
            label={"EMAIL"}
            required
            style={
              windowSize.innerWidth < 1550
                ? {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }
                : null
            }
          >
            <TextInput
              classNames={{
                root: classes.wrapper,
                defaultVariant: classes.filledVariant,
                input: classes.input,
              }}
              {...register("contactEmail")}
              error={errors.contactEmail && errors.contactEmail.message}
            />
          </InputWrapper>
          <InputWrapper
            classNames={{
              root: classes.root,
              label: classes.label,
            }}
            style={
              windowSize.innerWidth < 1550
                ? {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }
                : { paddingLeft: "5%" }
            }
            label={"ADRESA"}
            required
          >
            <TextInput
              classNames={{
                root: classes.wrapper,
                defaultVariant: classes.filledVariant,
                input: classes.input,
              }}
              {...register("address")}
              error={errors.address && errors.address.message}
            />
          </InputWrapper>
          <InputWrapper
            classNames={{
              root: classes.root,
              label: classes.label,
            }}
            label={"TELEFON"}
            required
            style={
              windowSize.innerWidth < 1550
                ? {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }
                : null
            }
          >
            <TextInput
              classNames={{
                root: classes.wrapper,
                defaultVariant: classes.filledVariant,
                input: classes.input,
              }}
              {...register("contactPhone")}
              error={errors.contactPhone && errors.contactPhone.message}
            />
          </InputWrapper>

          <InputWrapper
            classNames={{
              root: classes.root,
              label: classes.label,
            }}
            style={
              windowSize.innerWidth < 1550
                ? {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }
                : { paddingLeft: "5%" }
            }
            label={"MJESTO"}
            required
          >
            <TextInput
              classNames={{
                root: classes.wrapper,
                defaultVariant: classes.filledVariant,
                input: classes.input,
              }}
              {...register("county")}
              error={errors.county && errors.county.message}
            />
          </InputWrapper>
          <InputWrapper
            classNames={{
              root: classes.root,
              label: classes.label,
            }}
            label={"POŠTANSKI BROJ"}
            required
            style={
              windowSize.innerWidth < 1550
                ? {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }
                : null
            }
          >
            <TextInput
              classNames={{
                root: classes.wrapper,
                defaultVariant: classes.filledVariant,
                input: classes.input,
              }}
              {...register("postalCode")}
              error={errors.postalCode && errors.postalCode.message}
            />
          </InputWrapper>
          <InputWrapper
            classNames={{
              root: classes.root,
              label: classes.label,
            }}
            style={
              windowSize.innerWidth < 1550
                ? {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }
                : { paddingLeft: "5%" }
            }
            label={"OZNAKA OPERATER"}
            required
          >
            <TextInput
              classNames={{
                root: classes.wrapper,
                defaultVariant: classes.filledVariant,
                input: classes.input,
              }}
              {...register("receiptOperator")}
              error={errors.receiptOperator && errors.receiptOperator.message}
            />
          </InputWrapper>
          <InputWrapper
            classNames={{
              root: classes.root,
              label: classes.label,
            }}
            label={"OZNAKA LIKVIDATORA"}
            required
            style={
              windowSize.innerWidth < 1550
                ? {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }
                : null
            }
          >
            <TextInput
              classNames={{
                root: classes.wrapper,
                defaultVariant: classes.filledVariant,
                input: classes.input,
              }}
              {...register("receiptLiquidator")}
              error={
                errors.receiptLiquidator && errors.receiptLiquidator.message
              }
            />
          </InputWrapper>
          <InputWrapper
            classNames={{
              root: classes.root,
              label: classes.label,
            }}
            label={"OZNAKA POSLOVNOG PROSTORA"}
            style={
              windowSize.innerWidth < 1550
                ? {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }
                : { paddingLeft: "5%" }
            }
            required
          >
            <TextInput
              classNames={{
                root: classes.wrapper,
                defaultVariant: classes.filledVariant,
                input: classes.input,
              }}
              {...register("businessPremisesId")}
              error={
                errors.businessPremisesId && errors.businessPremisesId.message
              }
            />
          </InputWrapper>
          <InputWrapper
            classNames={{
              root: classes.root,
              label: classes.label,
            }}
            label={"OZNAKA TERMINAL"}
            required
            style={
              windowSize.innerWidth < 1550
                ? {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }
                : null
            }
          >
            <TextInput
              classNames={{
                root: classes.wrapper,
                defaultVariant: classes.filledVariant,
                input: classes.input,
              }}
              {...register("paymentDeviceId")}
              error={errors.paymentDeviceId && errors.paymentDeviceId.message}
            />
          </InputWrapper>
          <InputWrapper
            classNames={{
              root: classes.root,
              label: classes.label,
            }}
            label={"POREZ"}
            style={
              windowSize.innerWidth < 1550
                ? {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }
                : { paddingLeft: "5%" }
            }
            required
          >
            <TextInput
              classNames={{
                root: classes.wrapper,
                defaultVariant: classes.filledVariant,
                input: classes.input,
              }}
              {...register("vatRate")}
              error={errors.vatRate && errors.vatRate.message}
            />
          </InputWrapper>
        </div>
        <div
          className={
            windowSize.innerWidth < 1550 ? "" : classes.middleButtonContainer
          }
          style={
            windowSize.innerWidth < 1550
              ? { marginLeft: 350, width: "100%" }
              : null
          }
        >
          <Button
            type="submit"
            classNames={{
              filled: classes.buttonFilled,
              root: classes.buttonRoot,
            }}
          >
            Promijeni
          </Button>
        </div>
        <div className={classes.certifikatHeader}>
          <span style={{ color: "white" }}>CERTIFIKAT</span>
        </div>

        <div className={classes.certifikatContainer}>
          <InputWrapper
            classNames={{
              root: classes.certRoot,
              label: classes.cartPassLabel,
            }}
            labelElement="div"
            label={
              <div className={classes.certLabelContainer}>
                <span> FISKALNI CERTIFIKAT</span>
                <Image className={classes.certImage} src={images.uploadImg} />
              </div>
            }
          >
            <div className={classes.buttonContainer}>
              <label
                className={classes.uploadButton}
                htmlFor="formId"
                style={{
                  width: 125,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textAlign: "center",
                }}
              >
                <input
                  name=""
                  type="file"
                  id="formId"
                  hidden
                  accept=".p12,application/x-x509-ca-cert,application/x-pkcs12"
                  onChange={changeHandler}
                />
                {!!cert ? cert.name : "Učitaj certifikat"}
              </label>
            </div>
          </InputWrapper>

          <InputWrapper
            classNames={{
              root: classes.certRoot,
              label: classes.cartPassLabel,
            }}
            label={"LOZINKA"}
          >
            <TextInput
              classNames={{
                root: classes.wrapper,
                defaultVariant: classes.filledVariant,
                input: classes.certPassInput,
              }}
              type="password"
              {...register("password")}
              error={errors.password && errors.password.message}
            />
          </InputWrapper>
        </div>
      </form>
    </>
  );
};
export default OrganizationsGeneral;
