import React, { useEffect, useMemo, useState } from "react";
import statisticsItemStyles from "./StatistikaItemStyles";
import TableGeneric from "../Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardStatistics } from "../../store/slices/dashboardSlice";
import { Center, Group, Tooltip } from "@mantine/core";
import { ChevronDown, ChevronUp, Select, Selector } from "tabler-icons-react";

export const StatisticsItem = () => {
  const { classes } = statisticsItemStyles();

  const [state, setState] = useState({
    page: 0,
    size: 10,
    sortByAttribute: null,
    sortType: null,
  });

  useEffect(() => {
    dispatch(fetchDashboardStatistics(state));
  }, [state]);

  const tableData = useSelector((state) => state.dashboard.statisticsTableData);
  const getTotalPages = useSelector(
    (state) => state.dashboard.statisticsTotalPages
  );
  const dispatch = useDispatch();

  const columns = useMemo(
    () => [
      {
        Header: () => {
          return (
            <Tooltip
              withArrow
              transition="fade"
              transitionDuration={200}
              label={"Sortiraj po lokaciji"}
              styles={{
                arrow: { backgroundColor: "#3B5975" },
                body: { backgroundColor: "#3B5975" },
              }}
            >
              {" "}
              <Group
                position="apart"
                styles={{
                  root: {
                    /* "&:hover": {
                      backgroundColor: "grey",
                    }, */
                  },
                }}
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setState((prev) => {
                      return {
                        ...prev,
                        sortByAttribute: "locationName",
                        sortType:
                          prev.sortByAttribute !== "locationName"
                            ? "DESC"
                            : prev.sortType === "ASC"
                            ? "DESC"
                            : "ASC",
                      };
                    });
                  }}
                >
                  LOKACIJE
                </div>
                <Center>
                  {state.sortByAttribute === "locationName" ? (
                    state.sortType === "ASC" ? (
                      <ChevronDown />
                    ) : (
                      <ChevronUp />
                    )
                  ) : (
                    <Selector />
                  )}
                </Center>
              </Group>
            </Tooltip>
          );
        },
        accessor: "locationName",
      },
      {
        Header: () => {
          return (
            <Tooltip
              withArrow
              transition="fade"
              transitionDuration={200}
              label={"Sortiraj po punionici"}
              styles={{
                arrow: { backgroundColor: "#3B5975" },
                body: { backgroundColor: "#3B5975" },
              }}
            >
              {" "}
              <Group
                position="apart"
                styles={{
                  root: {
                    /* "&:hover": {
                      backgroundColor: "grey",
                    }, */
                  },
                }}
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setState((prev) => {
                      return {
                        ...prev,
                        sortByAttribute: "chargingStationsCount",
                        sortType:
                          prev.sortByAttribute !== "chargingStationsCount"
                            ? "DESC"
                            : prev.sortType === "ASC"
                            ? "DESC"
                            : "ASC",
                      };
                    });
                  }}
                >
                  PUNIONICE
                </div>
                <Center>
                  {state.sortByAttribute === "chargingStationsCount" ? (
                    state.sortType === "ASC" ? (
                      <ChevronDown />
                    ) : (
                      <ChevronUp />
                    )
                  ) : (
                    <Selector />
                  )}
                </Center>
              </Group>
            </Tooltip>
          );
        },
        accessor: "chargingStationsCount",
      },
      {
        Header: () => {
          return (
            <Tooltip
              withArrow
              transition="fade"
              transitionDuration={200}
              label={"Sortiraj po naplaćenom danu"}
              styles={{
                arrow: { backgroundColor: "#3B5975" },
                body: { backgroundColor: "#3B5975" },
              }}
            >
              {" "}
              <Group
                position="apart"
                styles={{
                  root: {
                    /* "&:hover": {
                      backgroundColor: "grey",
                    }, */
                  },
                }}
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setState((prev) => {
                      return {
                        ...prev,
                        sortByAttribute: "transactionAmount",
                        sortType:
                          prev.sortByAttribute !== "transactionAmount"
                            ? "DESC"
                            : prev.sortType === "ASC"
                            ? "DESC"
                            : "ASC",
                      };
                    });
                  }}
                >
                  NAPLAĆENO/DAN
                </div>
                <Center>
                  {state.sortByAttribute === "transactionAmount" ? (
                    state.sortType === "ASC" ? (
                      <ChevronDown />
                    ) : (
                      <ChevronUp />
                    )
                  ) : (
                    <Selector />
                  )}
                </Center>
              </Group>
            </Tooltip>
          );
        },
        accessor: "transactionAmount",
      },
    ],
    [state]
  );

  const dataRows = useMemo(() => {
    return tableData.map(
      ({ locationName, chargingStationsCount, transactionAmount }) => {
        return {
          locationName: locationName,
          chargingStationsCount: chargingStationsCount,
          transactionAmount: transactionAmount,
        };
      }
    );
  }, [tableData]);

  return (
    <>
      <div className={classes.userHeader}>
        <span>Statistika</span>
      </div>
      <div className={classes.divider}></div>
      <div className={classes.contentContainer}>
        <TableGeneric
          multipleTablesOnScreen={true}
          columns={columns}
          data={dataRows}
          totalPages={getTotalPages}
          manualPagination={true}
          manualPageIndex={state.page}
          manualPageSize={state.size}
          manualOnPageIndexChange={(pageIndex) => {
            setState((prev) => ({ ...prev, page: pageIndex }));
          }}
          manualOnPageSizeChange={(pageSize) => {
            setState((prev) => ({ ...prev, size: pageSize, page: 0 }));
          }}
        />
      </div>
    </>
  );
};
export default StatisticsItem;
