import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ChargersApi, NovaminaApi } from "../../api";

export const fetchAllChargers = createAsyncThunk(
  "locations/getAllChargers",
  async (args) => {
    const chargers = await ChargersApi.getAllChargers(args);
    if (chargers._embedded) {
      for (let charger of chargers._embedded.chargingStationResources) {
        charger.uuid = charger.id;
      }
    }

    return chargers;
  }
);

export const updateChargers = createAsyncThunk(
  "locations/updateChargers",
  async (args) => {
    return await ChargersApi.updateChargers(args);
  }
);

export const saveChargers = createAsyncThunk(
  "locations/saveChargers",
  async (args) => {
    return await ChargersApi.saveChargers(args);
  }
);

export const fetchConnectorUuidAndQr = createAsyncThunk(
  "chargers/fetchConnectorUuidAndQr",
  async (args, {}) => {
    try {
      const response = await NovaminaApi.getConnectorUuidAndQr(args);
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const initialState = {
  status: null,
  allChargers: [],
  totalPages: 0,
  totalElements: 0,
  pageSize: 10,
  pageIndex: 0,
};

const chargersSlice = createSlice({
  name: "chargers",
  initialState,
  reducers: {
    setPageSizeGlobalChargers: (state, action) => {
      return { ...state, pageSize: action.payload };
    },
    setPageIndexGlobalChargers: (state, action) => {
      return { ...state, pageIndex: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllChargers.pending, (state, action) => {
        return {
          ...state,
          status: "pending",
        };
      })
      .addCase(fetchAllChargers.fulfilled, (state, action) => {
        return {
          ...state,
          status: "fulfilled",
          allChargers: action.payload,
          totalPages: action.payload.page.totalPages,
          totalElements: action.payload.page.totalElements,
        };
      })
      .addCase(fetchAllChargers.rejected, (state, action) => {
        return {
          ...state,
          status: "error",
        };
      })
      .addCase(updateChargers.pending, (state, action) => {
        return {
          ...state,

          status: "pending",
        };
      })
      .addCase(updateChargers.fulfilled, (state, action) => {
        return {
          ...state,

          status: "fulfilled",
        };
      })
      .addCase(updateChargers.rejected, (state, action) => {
        return {
          ...state,
          status: "error",
        };
      })
      .addCase(saveChargers.pending, (state, action) => {
        return {
          ...state,

          status: "pending",
        };
      })
      .addCase(saveChargers.fulfilled, (state, action) => {
        return {
          ...state,

          status: "fulfilled",
        };
      })
      .addCase(saveChargers.rejected, (state, action) => {
        return {
          ...state,
          status: "error",
        };
      });
  },
});

export const { setPageIndexGlobalChargers, setPageSizeGlobalChargers } =
  chargersSlice.actions;
export default chargersSlice.reducer;
