import { useEffect } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { colors } from "../../constants/colors";
import energyItemStyles from "./EnergyItemStyles";
import { useDispatch, useSelector } from "react-redux";
import { fetchEnergyByDayGraphData } from "../../store/slices/dashboardSlice";
import * as dayjs from "dayjs";

export const EnergyItem = () => {
  const { classes } = energyItemStyles();
  const dispatch = useDispatch();
  const graphData = useSelector((state) => state.dashboard.energyGraphData);
  useEffect(() => {
    dispatch(fetchEnergyByDayGraphData());
  }, []);

  const parseDateToDayString = (date) => {
    var day = dayjs(date).locale("hr").format("ddd").toUpperCase();
    return day.slice(0, -1);
  };
  const prepareGraphData = (data) => {
    if (data !== [] || data === undefined) {
      const newArray = data.map((object) => {
        return {
          ...object,
          transactionDate: parseDateToDayString(object.transactionDate),
        };
      });
      return newArray;
    } else {
      return [];
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={classes.tooltipWrapper}>
          <p className={classes.tooltipHeader}>{`${label}`}</p>
          <p>Utrošak energije: {`${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };
  return (
    <>
      <div className={classes.userHeader}>
        <span>Energija</span>
      </div>
      <div className={classes.divider}></div>
      <div className={classes.contentContainer}>
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={prepareGraphData(graphData)}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid horizontal={true} vertical={false} />
            <XAxis
              dataKey="transactionDate"
              color={colors.textWhite}
              stroke={colors.textWhite}
              fill={colors.textWhite}
            />
            <YAxis
              color={colors.textWhite}
              stroke={colors.textWhite}
              fill={colors.textWhite}
            />
            <Tooltip content={<CustomTooltip />} />
            <Area
              connectNulls
              type="monotone"
              dataKey="energySpent"
              stroke={colors.graphMainColor}
              fill={colors.graphMainColor}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};
export default EnergyItem;
