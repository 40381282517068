import { createStyles } from "@mantine/core";

const vehiclesModalStyles = createStyles((theme) => ({
  headerContainer: {
    backgroundColor: "#00498F",
    color: "white",
    height: 60,
    margin: 0,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    justifyContent: "center",
  },
  headerText: {
    color: "white",
    fontSize: 40,
  },

  groupContainer: {
    padding: 20,
    backgroundColor: "#7bb7f0",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
}));
export default vehiclesModalStyles;
