import { Routes, Route, BrowserRouter } from "react-router-dom";
import LoginLayout from "./components/LoginLayout/LoginLayout";
import LoginInput from "./components/LoginInput/LoginInput";
import { useSelector } from "react-redux";
import { ProtectedRoute } from "./utils/ProtectedRoutes";
import jwt_decode from "jwt-decode";
import AdminLayout from "./components/AdminLayout/AdminLayout";
import Vehicles from "./components/Vehicles/Vehicles";
import Dashboard from "./components/Dashboard/Dashboard";
import Locations from "./components/Locations/Locations";
import Organizations from "./components/Organizations/Organizations";
import LocationsGeneral from "./components/Locations/LocationsGeneral";
import Users from "./components/users/Users";
import Transactions from "./components/Transactions/Transactions";

const App = () => {
  const accessToken = useSelector((state) => state.otpAuth.accessToken);

  let decodedToken = !accessToken ? "" : jwt_decode(accessToken);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginLayout />}>
            <Route index element={<LoginInput />} />
          </Route>

          <Route
            element={
              <ProtectedRoute
                accessToken={decodedToken}
                redirectPath="/login"
              />
            }
          >
            <Route path="/" element={<AdminLayout />}>
              <Route index element={<Dashboard />} />
              <Route path="organizations" element={<Organizations />} />
              <Route path="users" element={<Users />} />
              <Route path="users/transactions/:id" element={<Transactions />} />
              <Route path="vehicles" element={<Vehicles />} />
              <Route path="locations" element={<Locations />}>
                <Route path=":locationsId" element={<LocationsGeneral />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
