import {
  Button,
  Grid,
  Group,
  MantineProvider,
  Modal,
  Tabs,
  Text,
} from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllVehicles,
  deleteVehicles,
  setPageIndexVehicles,
} from "../../store/slices/vehiclesSlice";
import TableGeneric from "../Table/Table";
import VehiclesModal from "./VehiclesModal";
import VehiclesModalDelete from "./VehiclesModalDelete";
import vehiclesStyles from "./VehiclesStyle";
import { colors } from "../../constants/colors";

const StyledTabs = (props) => {
  return (
    <Tabs
      variant="unstyled"
      styles={(theme) => ({
        tabControl: {
          width: "18%",
          backgroundColor: colors.backgroundDark,
          color: colors.textWhite,
          border: "0px",
          padding: `${theme.spacing.lg}px ${theme.spacing.xl}px`,
        },
        tabActive: {
          backgroundColor: colors.tabActive,
          color: colors.textWhite,
        },
        body: { padding: 0 },
      })}
      {...props}
    />
  );
};

const Vehicles = () => {
  const dispatch = useDispatch();
  const { classes } = vehiclesStyles();
  /***const getPage = useSelector(
    (state) => state.vehicles.allVehicles.page
  );*/
  const getAllVehicles = useSelector(
    (state) => state.vehicles.allVehicles?._embedded?.vehicleResources
  );
  const getTotalPages = useSelector((state) => state.vehicles.totalPages);

  const statusCode = useSelector((state) => state.vehicles.status);
  const pageIndex = useSelector((state) => state.vehicles.pageIndex);
  const [pageSize, setPageSize] = useState(10);

  const [showVehiclesModal, setShowVehiclesModal] = useState(false);
  const [showVehicleModalData, setVehicleModalData] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalData, setDeleteModalData] = useState(null);

  const dispatchAllVehicles = async () => {
    try {
      await dispatch(fetchAllVehicles({ page: pageIndex, size: pageSize }));
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    dispatchAllVehicles();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Vozilo",
        accessor: "brand", // accessor is the "key" in the data
      },
      {
        Header: "Godina",
        accessor: "year",
      },
      {
        Header: "Model",
        accessor: "model",
      },
      {
        Header: "Baterija",
        accessor: "battery",
      },
      {
        Header: "Domet",
        accessor: "range",
      },
      {
        Header: "",
        accessor: "id",
        Cell: (props) => {
          const data = props.row.original;

          return (
            <>
              <Group position="right">
                <Button
                  color={"green"}
                  size="sm"
                  onClick={() => {
                    openVehiclesModal(data);
                  }}
                >
                  {"Promijeni"}
                </Button>
                <Button
                  color={"red"}
                  size="sm"
                  onClick={() => {
                    //setShowDeleteModal(true);
                    openDeleteModal(data);
                  }}
                >
                  {"Obriši"}
                </Button>
              </Group>
            </>
          );
        },
      },
    ],
    []
  );

  const openVehiclesModal = (data) => {
    setShowVehiclesModal(true);
    setVehicleModalData(data);
  };

  const closeVehicleModal = () => {
    setShowVehiclesModal(false);
    setVehicleModalData(null);
  };

  const openDeleteModal = (data) => {
    setShowDeleteModal(true);
    setDeleteModalData(data);
  };
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setDeleteModalData(null);
  };

  return (
    <>
      <div className={classes.vehiclesHeader}>
        <div className={classes.vehiclesSubHeader}>
          <p>Vozila</p>
        </div>
        <div style={{ color: "white" }}>
          <span style={{ padding: 5 }}>{"Dodaj vozilo"}</span>
          <Button
            styles={{ root: { backgroundColor: "#00498F" } }}
            onClick={() => openVehiclesModal()}
          >
            +
          </Button>
        </div>
      </div>
      <div className={classes.divider}></div>
      <StyledTabs>
        <Tabs.Tab label="OPĆENITO">
          <TableGeneric
            columns={columns}
            data={getAllVehicles || []}
            totalPages={getTotalPages}
            manualPagination={true}
            manualPageIndex={pageIndex}
            manualPageSize={pageSize}
            manualOnPageIndexChange={(pageIndex) => {
              dispatch(setPageIndexVehicles(pageIndex));
              dispatch(fetchAllVehicles({ page: pageIndex, size: pageSize }));
            }}
            manualOnPageSizeChange={(pageSize) => {
              dispatch(fetchAllVehicles({ size: pageSize, page: 0 }));
              setPageSize(pageSize);
              //setPageIndex(0)
            }}
          />
        </Tabs.Tab>
      </StyledTabs>

      <VehiclesModal
        show={showVehiclesModal}
        data={showVehicleModalData}
        close={closeVehicleModal}
        page={pageIndex}
        size={pageSize}
      />

      <VehiclesModalDelete
        show={showDeleteModal}
        data={deleteModalData}
        close={closeDeleteModal}
        page={pageIndex}
        size={pageSize}
      />
    </>
  );
};

export default Vehicles;
