export const colors = {
  backgroundLight: "#80B4E2",
  backgroundDark: "#00498F",
  backgroundGraph: "#3B5975",
  graphMainColor: "#CD5E37",
  tableHeaderDark: "#102A43",
  buttonGreen: "#62BB47",
  buttonRed: "#F24668",
  textWhite: "white",
  tabActive: "#102A43",
  paginationInactive: "#B9D9F7",
};
