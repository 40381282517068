import { createStyles } from "@mantine/core";

const vehiclesStyles = createStyles((theme) => ({
    vehiclesHeader: {
        display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center",
         
      },
      vehiclesSubHeader: {
        backgroundColor: "#00498F",
        height: 40,
        width: "18%",
        color: "white",
        
        
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px",
      },
      divider: {
        backgroundColor: "#00498F",
        height: 20,
        width: "100%",
        borderTopRightRadius: "15px",
      },
    }));
    
 
  export default vehiclesStyles;