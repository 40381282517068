import { Button, Group, InputWrapper, Modal, TextInput } from "@mantine/core";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { DatePicker } from "@mantine/dates";
import useLocationsRulesModalStyles from "./LocationsRulesModalStyles";
import { useEffect, useState } from "react";
import "dayjs/locale/hr";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllRules,
  saveRules,
  updateRules,
} from "../../store/slices/rulesSlice";
import { showNotification } from "@mantine/notifications";
import { Calendar } from "tabler-icons-react";
import { format } from "date-fns";

const schema = yup.object().shape({
  name: yup.string().required("Obavezno polje"),
  startDate: yup.date().required("Obavezno polje").nullable(),

  endDate: yup
    .date()

    .typeError("Polje za datum ne smije ostati prazno")
    .when(
      "startDate",
      (startDate, yup) =>
        startDate &&
        yup.min(startDate, "Datum do ne može biti veći od datuma od")
    )

    .required("Obavezno polje"),
});

const LocationsRulesModal = (props) => {
  const { classes } = useLocationsRulesModalStyles();

  const dispatch = useDispatch();
  const isNewLocationsRules = () => !(props.data && props.data.id);

  const pageIndexRules = useSelector((state) => state.rules.pageIndex);
  const pageSizeRules = useSelector((state) => state.rules.pageSize);

  const dispatchUpdateLocationsRules = async (data) => {
    try {
      await dispatch(
        updateRules({
          ...data,
          locationId: props.locationId,
          startDate: format(new Date(data.startDate), "yyyy-MM-dd"),
          endDate: format(new Date(data.endDate), "yyyy-MM-dd"),
        })
      )
        .unwrap()
        .then(() => {
          showNotification({
            message: "Uspješno ste ažurirali pravilo",
            color: "green",
          });
        })
        .catch(() => {
          showNotification({
            title: "Greška",
            message: "Neočekivana greška",
            color: "red",
          });
        });

      await dispatch(
        fetchAllRules({
          locationId: props.locationId,
          page: props.page,
          size: props.size,
        })
      );
    } catch (error) {
      throw error;
    }
  };

  const dispatchSaveLocationsRules = async (data) => {
    console.log({ ...data, locationId: props.locationId });
    try {
      await dispatch(
        saveRules({
          ...data,
          locationId: props.locationId,
          startDate: format(new Date(data.startDate), "yyyy-MM-dd"),
          endDate: format(new Date(data.endDate), "yyyy-MM-dd"),
        })
      )
        .unwrap()
        .then(() => {
          showNotification({
            message: "Uspješno ste spremili pravilo",
            color: "green",
          });
        })
        .catch(() => {
          showNotification({
            title: "Greška",
            message: "Neočekivana greška",
            color: "red",
          });
        });

      await dispatch(
        fetchAllRules({
          locationId: props.locationId,
          page: pageIndexRules,
          size: pageSizeRules,
        })
      );
    } catch (error) {
      throw error;
    }
  };

  const {
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data) => {
    console.log("data ", data);
    isNewLocationsRules()
      ? dispatchSaveLocationsRules(data)
      : dispatchUpdateLocationsRules(data);
    reset();
    props.close();
  };
  useEffect(() => {
    let defaultValues = {};
    defaultValues.id = props.data?.id;
    defaultValues.name = props.data?.name;
    defaultValues.startDate =
      props.data?.startDate && new Date(props.data?.startDate);

    defaultValues.endDate =
      props.data?.endDate && new Date(props.data?.endDate);

    reset({ ...defaultValues });
  }, [props]);

  return (
    <>
      <div>
        <Modal
          withCloseButton={false}
          centered
          opened={props.show}
          onClose={() => props.close()}
          title="Pravila"
          sx={() => ({
            ".mantine-vos779": classes.mantine_vos779,

            ".mantine-l553vn": classes.mantine_l553vn,
          })}
          classNames={{
            header: classes.headerContainer,
            title: classes.headerText,
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputWrapper
              required
              styles={{
                root: { textAlign: "left" },
                label: { color: "#00498F" },
              }}
              label={"Naziv"}
              error={errors.name && errors.name.message}
            >
              <TextInput autoFocus {...register("name")} />
            </InputWrapper>
            <Controller
              control={control}
              name="startDate"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { isTouched, isDirty, error },
                formState,
              }) => (
                <DatePicker
                  styles={{
                    root: { textAlign: "left" },
                    label: { color: "#00498F" },
                  }}
                  clearable={false}
                  locale="hr"
                  ref={ref}
                  inputFormat="DD.MM.YYYY"
                  label="Datum od:"
                  minDate={new Date()}
                  onChange={onChange} // send value to hook form
                  value={value}
                  placeholder={"Odaberite početni datum"}
                  error={errors.startDate && errors.startDate.message}
                  required
                  icon={<Calendar size={16} />}
                />
              )}
            />
            <Controller
              control={control}
              name="endDate"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { isTouched, isDirty, error },
                formState,
              }) => (
                <DatePicker
                  styles={{
                    root: { textAlign: "left" },
                    label: { color: "#00498F" },
                  }}
                  ref={ref}
                  clearable={false}
                  inputFormat="DD.MM.YYYY"
                  label="Datum do:"
                  locale="hr"
                  minDate={new Date()}
                  onChange={onChange} // send value to hook form
                  value={value}
                  placeholder={"Odaberite završni datum"}
                  error={errors.endDate && errors.endDate.message}
                  required
                  icon={<Calendar size={16} />}
                />
              )}
            />

            <Group position="center" className={classes.groupContainer}>
              <Button type="submit" color="green" size="lg">
                {"Spremi"}
              </Button>
              <Button
                color="red"
                size="lg"
                onClick={() => {
                  props.close();
                }}
              >
                {"Otkaži"}
              </Button>
            </Group>
          </form>
        </Modal>
      </div>
    </>
  );
};

export default LocationsRulesModal;
