export const isValidEmail = (email) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

export const isEmailExisting = () => {};

export const renderError = (errors) => {
  if (errors.email && errors.email.type === "required")
    return "Polje je obavezno!";
  if (errors.email && errors.email.type === "isValidEmail") {
    return "Unesite ispravan e-mail!";
  }
  if (errors.puniNaziv && errors.puniNaziv.type === "required") {
    return "Unesite naziv!";
  }
};
