import useStyles from "./loginStyles";
import { Outlet } from "react-router-dom";
import { Image } from "@mantine/core";
import images from "../../constants/images";

const LoginLayout = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.loginDivLeft}>
        <Image className={classes.loginPictureLeft} src={images.orangeCar} />
      </div>
      <div className={classes.loginDivContent}>
        <div className={classes.loginDivInput}>
          <Image
            className={classes.loginPicture}
            src={images.mainLogoBig}
            style={{ width: 350, height: 350, marginTop: 150 }}
          />
          <div className={classes.loginForm}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
