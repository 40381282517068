import { Button, Group } from "@mantine/core";
import { format, parseISO } from "date-fns";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { setScreenType } from "../../store/slices/locationsSlice";
import {
  fetchAllRules,
  setPageIndexGlobalRules,
  setPageSizeGlobalRules,
} from "../../store/slices/rulesSlice";
import TableGeneric from "../Table/Table";
import LocationsRulesModal from "./LocationsRulesModal";
import LocationsRulesModalDelete from "./LocationsRulesModalDelete";

const LocationsRules = () => {
  const dispatch = useDispatch();

  const [rowData] = useOutletContext();

  const locationId = rowData.id;

  const pageIndexRules = useSelector((state) => state.rules.pageIndex);
  const pageSizeRules = useSelector((state) => state.rules.pageSize);

  const [showLocationsRulesModal, setShowLocationsRulesModal] = useState(false);
  const [showLocationsRulesModalData, setShowLocationsRulesModalData] =
    useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteModalData, setDeleteModalData] = useState(null);

  const getTotalPages = useSelector((state) => state.rules.totalPages);
  const getAllRules = useSelector(
    (state) => state.rules.allRules?._embedded?.ruleResources
  );

  const openLocationRulesModalData = (data) => {
    setShowLocationsRulesModalData(data);
    setShowLocationsRulesModal(true);
  };

  const closeLocationRulesModalData = () => {
    setShowLocationsRulesModal(false);
    setShowLocationsRulesModalData(null);
  };

  const openDeleteModal = (data) => {
    setShowDeleteModal(true);
    setDeleteModalData(data);
  };
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setDeleteModalData(null);
  };

  const dispatchAllRules = async () => {
    try {
      await dispatch(
        fetchAllRules({
          locationId: locationId,
          page: pageIndexRules,
          size: pageSizeRules,
        })
      ).unwrap();
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    dispatch(setScreenType("rules"));
    dispatch(setPageIndexGlobalRules(0));
    dispatchAllRules();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Naziv",
        accessor: "name",
      },

      {
        Header: "Od",
        accessor: "startDate",
        Cell: (props) => {
          return props.value ? dayjs(props.value).format("DD.MM.YYYY.") : null;
        },
      },
      {
        Header: "Do",
        accessor: "endDate",
        Cell: (props) => {
          return props.value ? dayjs(props.value).format("DD.MM.YYYY.") : null;
        },
      },
      {
        Header: "Kreiran",
        accessor: "createdAt",
        Cell: (props) => {
          return props.value
            ? dayjs(props.value).format("DD.MM.YYYY. HH:mm")
            : null;
        },
      },
      {
        Header: "",
        accessor: "id",
        Cell: (props) => {
          const data = props.row.original;

          return (
            <>
              <Group position="right">
                <Button
                  color={"green"}
                  size="sm"
                  onClick={() => {
                    openLocationRulesModalData(data);
                  }}
                >
                  {"Promijeni"}
                </Button>
                <Button
                  color={"red"}
                  size="sm"
                  onClick={() => {
                    openDeleteModal(data);
                  }}
                >
                  {"Obriši"}
                </Button>
              </Group>
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <TableGeneric
        columns={columns}
        data={getAllRules || []}
        totalPages={getTotalPages}
        manualPagination={true}
        manualPageIndex={pageIndexRules}
        manualPageSize={pageSizeRules}
        manualOnPageIndexChange={(pageIndex) => {
          // setPageIndex(pageIndex);

          //for global modal in Locations.js file
          dispatch(setPageIndexGlobalRules(pageIndex));

          dispatch(
            fetchAllRules({
              locationId: locationId,
              page: pageIndex,
              size: pageSizeRules,
            })
          );
        }}
        manualOnPageSizeChange={(pageSize) => {
          dispatch(
            fetchAllRules({ locationId: locationId, size: pageSize, page: 0 })
          );
          //setPageSize(pageSize);

          //for global modal in Locations.js file
          dispatch(setPageSizeGlobalRules(pageSize));
          dispatch(setPageIndexGlobalRules(0));

          //setPageIndex(0)
        }}
      />
      <LocationsRulesModal
        show={showLocationsRulesModal}
        data={showLocationsRulesModalData}
        close={closeLocationRulesModalData}
        page={pageIndexRules}
        size={pageSizeRules}
        locationId={locationId}
      />
      <LocationsRulesModalDelete
        show={showDeleteModal}
        data={deleteModalData}
        close={closeDeleteModal}
        page={pageIndexRules}
        size={pageSizeRules}
        locationId={locationId}
      />
    </>
  );
};

export default LocationsRules;
