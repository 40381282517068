import { Group, Modal, Button, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteVehicles,
  fetchAllVehicles,
  setPageIndexVehicles,
} from "../../store/slices/vehiclesSlice";
import vehiclesModalDeleteStyles from "./VehiclesModalDeleteStyle";

const VehiclesModalDelete = (props) => {
  const { show, data, close, size } = props;
  const dispatch = useDispatch();

  const { classes } = vehiclesModalDeleteStyles();

  const dispatchDeleteVehicles = async (data) => {
    try {
      await dispatch(deleteVehicles(data))
        .unwrap()
        .then(() => {
          showNotification({
            message: "Uspješno ste obrisali vozilo",
            color: "green",
          });
        })
        .catch(() => {
          showNotification({
            title: "Greška",
            message:
              "Ne možete obrisati vozilo koje je dodijeljeno nekom korisniku",
            color: "red",
          });
        });

      await dispatch(fetchAllVehicles({ page: 0, size: size })).unwrap();
      dispatch(setPageIndexVehicles(0));
    } catch (error) {
      throw error;
    }
  };

  return (
    <Modal
      centered
      withCloseButton={false}
      opened={show}
      title={"Upozorenje"}
      onClose={() => close()}
      sx={() => ({
        /*".mantine-1rfnitv": {
          padding: 0,
          backgroundColor: "#00498F",
          borderRadius: 10,
        
        },*/
        ".mantine-121w2fi": {
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          backgroundColor: "#fa5252",
          justifyContent: "center",
          margin: 0,
          padding: 0,
          border: "1px solid #fa5252",
        },
        ".mantine-1k3lcel": {
          backgroundColor: "#7bb7f0",
          borderRadius: 11,
          padding: 0,
        },
      })}
      classNames={{
        modal: classes.modalContainer,
        title: classes.headerText,
      }}
    >
      <Text align="center" className={classes.bodyText}>
        {"Želite li obrisati vozilo?"}
      </Text>
      <div className={classes.buttonContainer}>
        <Group position="center">
          <Button
            color="green"
            size="sm"
            onClick={() => {
              dispatchDeleteVehicles(data);
              close();
            }}
          >
            {"Obriši"}
          </Button>
          <Button color="red" size="sm" onClick={() => close()}>
            {"Odustani"}
          </Button>
        </Group>
      </div>
    </Modal>
  );
};

export default VehiclesModalDelete;
